



















































import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import DateFormatter from '@/components/DateFormatter.vue';
import { Affiliation, AffiliationStatus } from '@/types/affiliates';
import { AVATAR_BOT_3 } from '@/libs/avatar';
import MAvatar from '@/shared/components/MAvatar.vue';
import MSwitch from '@/shared/components/MSwitch.vue';

@Component({
  components: { DateFormatter, MAvatar, MSwitch },
})
export default class AffiliateDetails extends Vue {
  @Prop({ type: Object }) affiliation: Affiliation;

  defaultAvatar = AVATAR_BOT_3;

  get name() {
    return _.get(this.affiliation, 'affiliate.name', '');
  }

  get email() {
    return _.get(this.affiliation, 'affiliate.email', '');
  }

  get affiliationSince() {
    return _.get(this.affiliation, 'created_at', '');
  }

  get allPrograms(): string[] {
    return _.get(this.affiliation, 'affiliate.programs', []);
  }

  get listPrograms(): string {
    return this.allPrograms.join(', ');
  }

  get countPrograms(): number {
    return this.allPrograms.length;
  }

  isEnabled(status: AffiliationStatus) {
    return status === AffiliationStatus.ACCEPTED;
  }

  toggleActivation(status: boolean) {
    this.$emit('toggle-activation', {
      status,
      affiliation: this.affiliation,
    });
  }
}
