<template>
  <div
    class="hero-tips__card tlw-grid tlw-items-center tlw-w-full tlw-bg-white tlw-shadow-lg tlw-rounded-lg md:tlw-pb-0 tlw-pb-3"
  >
    <div class="tlw-pr-20 tlw-pl-8 md:tlw-pt-0 tlw-pt-6">
      {{ $t('sparkaffiliates.producer-panel.tips.subtitle') }}
    </div>

    <div class="hero-tips__card-list tlw-grid tlw-pr-6 md:tlw-pl-0 tlw-pl-6 tlw-py-2">
      <TipsItem title="Aprenda a criar seu primeiro programa" url="#" />
      <TipsItem title="Utilize modelos e programas de afiliados" url="#" />
      <TipsItem title="Descubra como definir as melhores comissões" url="#" />
      <TipsItem title="Construa modelos de programas e reutilize" url="#" />
      <TipsItem title="Saiba como adicionar novas afiliações" url="#" />
      <TipsItem title="Torne seus estudantes em novas afiliações" url="#" />
    </div>

    <img :src="images.heroTips" class="md:tlw-block tlw-hidden tlw-justify-self-end" alt="Personagem" />
  </div>
</template>

<script>
import TipsItem from './shared/TipsItem';

export default {
  name: 'TipsCard',
  components: {
    TipsItem,
  },
  data() {
    return {
      images: {
        heroTips: require('@/assets/images/SparkAffiliates/ProducerPanel/hero-tips.svg'),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.hero-tips__card {
  grid-template-columns: 1fr;
}

.hero-tips__card-list {
  grid-template-columns: 1fr;
}

@media (min-width: $screen-bs-md) {
  .hero-tips__card {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .hero-tips__card-list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
</style>
