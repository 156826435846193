<template>
  <div class="d-flex flex-column flex-fill">
    <hsLoading v-if="isLoading" class="flex-fill" />
    <section
      v-else
      data-testid="program-info-content"
      class="py-1 px-3 py-lg-4 px-lg-5 mt-5 position-relative row mb-4 pt-5 d-flex flex-row"
    >
      <span class="col-6">SPARK AFFILIATES PROGRAM INFO</span>
      {{ programParams }}
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { hsLoading } from '@/components';

export default {
  name: 'ProgramInfo',
  components: {
    hsLoading,
  },
  data() {
    return {
      isLoading: false,
      programParams: {},
    };
  },
  created() {
    this.programParams = this.$route.params;
  },
  computed: {
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
  },
  methods: {},
};
</script>
