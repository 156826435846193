<template>
  <ProgramList v-if="hasPrograms" v-on="$listeners" />
  <EmptyProgram v-else v-on="$listeners" @goToNewProgramPage="goToNewProgramPage" />
</template>

<script>
import ProgramList from '@/sparkaffiliates/views/ProgramList';
import EmptyProgram from '@/sparkaffiliates/views/EmptyProgram';

export default {
  name: 'ProgramListTab',
  components: {
    ProgramList,
    EmptyProgram,
  },
  props: {
    programs: {
      type: Array,
      required: true,
    },
  },
  created() {
    document.title = `Meus afiliados – ${process.env.VUE_APP_APPLICATION_TITLE}`;
  },
  methods: {
    goToNewProgramPage() {
      this.setSelectedTab(this.newProgramTab);
    },
  },
  computed: {
    hasPrograms() {
      return this.programs.length > 0;
    },
  },
};
</script>
