<template>
  <div class="tlw-grid tlw-gap-6 media-kit-product-card__content">
    <div class="media-kit-product-card__offer-list">
      <div class="tlw-font-semibold tlw-mb-4">
        {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.product') }}
      </div>
      <div class="tlw-text-sm tlw-font-semibold tlw-mb-2">{{ product.title }}</div>
      <div v-for="offer in product.product_offers" :key="offer.id" class="tlw-mb-1 tlw-text-sm">
        {{ displayOffer(offer) }}
      </div>
    </div>
    <div class="media-kit-product-card__commission-type">
      <div class="tlw-font-semibold tlw-mb-4">
        {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.commission-style') }}
      </div>
      <div class="tlw-font-semibold tlw-text-sm tlw-mb-2">
        {{
          $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.commission-percent')
        }}
      </div>
      <hs-badge v-if="product.commission" variant="purple" pill>Comissão {{ product.commission }}%</hs-badge>
    </div>
    <div class="media-kit-product-card__media-form">
      <div class="tlw-font-semibold tlw-mb-4">
        {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.media-kit') }}
      </div>
      <hs-group v-if="!lock" class="tlw-w-5/6">
        <label>{{
          $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.url')
        }}</label>

        <hs-input
          type="text"
          placeholder="Cole aqui o endereço do link. Exemplo: https://drive.google.com/meukit"
          @blur="$v.mediaKit.source.$touch()"
          :state="!$v.mediaKit.source.$error ? null : false"
          v-model="mediaKit.source"
        >
          <template slot="feedback" v-if="$v.mediaKit.source.$error">
            <hs-form-invalid-feedback :state="false">
              <span
                v-if="!$v.mediaKit.source.required"
                v-html="
                  $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.required')
                "
              />
              <span
                v-if="$v.mediaKit.source.required && !$v.mediaKit.source.url"
                v-html="$t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.url.error')"
              />
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
        <div class="tlw-text-sm tlw-font-semibold tlw-mt-6">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.media-types') }}
        </div>
        <div class="tlw-flex tlw-justify-between">
          <div class="tlw-grid tlw-grid-cols-3 tlw-gap-4 tlw-mt-4">
            <hs-checkbox
              v-for="mediaType in $options.allMediaTypes"
              :key="mediaType"
              :value="mediaType"
              v-model="mediaKit.media_types"
              @blur="$v.mediaKit.media_types.$touch()"
              :state="!$v.mediaKit.media_types.$error ? null : false"
            >
              {{ mediaType }}
            </hs-checkbox>
          </div>

          <div class="tlw-self-end">
            <MButton
              :label="
                $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.add-media')
              "
              variant="primary"
              :class="$v.mediaKit.$invalid ? 'tlw-cursor-not-allowed' : ''"
              :disabled="$v.mediaKit.$invalid"
              @click="addMediaKit"
            />
          </div>
        </div>
      </hs-group>
      <div v-else class="tlw-grid tlw-grid-cols-7">
        <div class="tlw-col-span-5">
          <div class="tlw-text-sm tlw-font-semibold">{{ mediaKit.name }}</div>
          <div class="tlw-text-sm tlw-mt-1">
            {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.card.address') }}
            <a :href="mediaKit.source" class="address-link tlw-break-words" target="_blank">{{ mediaKit.source }}</a>
          </div>
          <div class="tlw-mt-3 tlw-flex tlw-gap-x-3">
            <hs-badge v-for="mtype in mediaKit.media_types" :key="mtype" variant="purple" pill>
              {{ mtype }}
            </hs-badge>
          </div>
        </div>
        <div class="tlw-col-span-2 tlw-flex tlw-gap-3 tlw-justify-end">
          <div class="media-kit-product-card__primary-icon tlw-cursor-pointer" @click="lock = false">
            <hs-icon icon="pencil-alt" />
          </div>
          <div class="media-kit-product-card__danger-icon tlw-cursor-pointer" @click="$bvModal.show('delete-modal')">
            <hs-icon icon="trash-alt" />
          </div>
        </div>
      </div>
    </div>
    <div class="media-kit-product-card__media-icon tlw-justify-self-end">
      <img :src="images.mediaKitIcon" />
    </div>
    <MediaKitDeletionModal :product-id="productId" />
    <MConfirmModal
      @confirm="deleteMediaKit"
      @cancel="backToProgram"
      id="delete-modal"
      variant="delete"
      :title="$t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.deletion-modal.title')"
      :text="
        $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.deletion-modal.subtitle')
      "
      :confirmButtonLabel="
        $t(
          'sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.deletion-modal.continue-button'
        )
      "
      :cancelButtonLabel="
        $t(
          'sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.deletion-modal.secondary-button'
        )
      "
    />
  </div>
</template>

<script>
import _ from 'lodash';
import MediaKitDeletionModal from './MediaKitDeletionModal';
import { required, sameAs, url } from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
import { hsConfirmModal } from '@/components';
import MConfirmModal from '@/shared/components/MConfirmModal.vue';
import MButton from '@/shared/components/MButton.vue';

export default {
  components: {
    MediaKitDeletionModal,
    MConfirmModal,
    MButton,
  },
  allMediaTypes: ['Instagram', 'Google Ads', 'Blog', 'Facebook', 'YouTube', 'Web'],
  props: {
    product: {
      type: Object,
      required: true,
    },
    offers: {
      type: Object,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
  },
  data() {
    const mediaKit = (this.product.media_kits || [])[0] || {
      name: `Mídia kit / ${this.product.title}`,
      kind: 'link',
      source: '',
      media_types: [],
    };
    return {
      lock: Boolean(mediaKit.source),
      mediaKit,
      images: {
        mediaKitIcon: require('@/assets/images/SparkAffiliates/Programs/newbox.svg'),
      },
    };
  },
  validations: {
    mediaKit: {
      source: {
        required,
        url,
      },
      media_types: {
        required,
      },
    },
  },

  computed: {
    productMediaKit() {
      return _.get(this.validation.form.program_products[this.product.id], 'media_kits', {});
    },
    productMediaKitURL() {
      return _.get(this.productMediaKit, 'url', { $error: null });
    },
    productId() {
      return this.product.sparkecommerce_product_id || this.product.id;
    },
  },
  methods: {
    displayOffer(offer) {
      return this.offers[this.productId].find(
        ({ sparkecommerce_offer_id: soid }) => soid === offer.sparkecommerce_products_id
      ).title;
    },
    addMediaKit() {
      const { mediaKit } = this;
      this.lock = true;
      this.$emit('add-media-kit', { productId: this.productId, mediaKit });
    },
    deleteMediaKit() {
      this.lock = false;
      this.mediaKit.source = '';
      this.mediaKit.media_types = [];
      this.$bvModal.hide('delete-modal');
    },
    backToProgram() {
      this.$bvModal.hide('delete-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.media-kit-product-card__content {
  grid-template-columns: 3fr 3fr 1fr;
  grid-template-areas:
    'offer-list commission-type media-icon'
    'media-form media-form media-form';
}

.media-kit-product-card__commission-type {
  grid-area: commission-type;
}

.media-kit-product-card__offer-list {
  grid-area: offer-list;
}

.media-kit-product-card__media-form {
  grid-area: media-form;
}

.media-kit-product-card__media-icon {
  grid-area: media-icon;
}

.media-kit-product-card__danger-icon {
  color: $cherry;
  height: fit-content;
}
.program-creator-form__step--input-valid {
  color: var(--color-gray-800);
}

.media-kit-product-card__primary-icon {
  color: $cyan;
  height: fit-content;
}
.address-link {
  color: $blue;
  text-decoration: underline;
}

/deep/ .custom-control-input:hover {
  border-color: $purple-dark;
}
/deep/ .custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: $purple-dark;
  background-color: $purple-dark;
}
/deep/ .custom-checkbox:hover .custom-control-input:not(:disabled) ~ .custom-control-label:before {
  border-color: $purple-dark;
}
/deep/.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: $purple-dark;
  background-color: $purple-dark;
}
/deep/.custom-control-input:hover ~ .custom-control-label:before {
  border-color: $purple-dark;
}
/deep/.custom-control-input:not(:focus) ~ .custom-control-label:before {
  border: 1px solid $grey-ba;
}
/deep/.custom-control-input:active ~ .custom-control-label:before {
  background-color: transparent;
  border-color: $purple-dark;
}
</style>
