<template>
  <program-editor-form-layout
    :page-number="2"
    :title="$t('sparkaffiliates.programs-list.new-program.program-creator-form.product-form.title')"
    :subtitle="$t('sparkaffiliates.programs-list.new-program.program-creator-form.product-form.subtitle')"
  >
    <div class="tlw-flex tlw-flex-col">
      <div class="tlw-w-full tlw-bg-white tlw-p-8">
        <div class="tlw-mb-6 tlw-font-semibold tlw-text-xl">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.product-form.form-input.title') }}
        </div>
        <hs-group class="tlw-max-w-lg">
          <label>
            {{
              $t('sparkaffiliates.programs-list.new-program.program-creator-form.product-form.form-input.product-label')
            }}
          </label>
          <div class="hs-multiselect">
            <MXMultiSelect
              v-model="form.program_products"
              :options="products"
              :multiple="true"
              :searchable="false"
              :showLabels="false"
              :custom-label="displayLabel"
              label="title"
              :placeholder="
                $t('sparkaffiliates.programs-list.new-program.program-creator-form.product-form.form-input.title')
              "
              track-by="sparkecommerce_product_id"
              @blur="validation.form.program_products.$touch()"
            />
          </div>
        </hs-group>
        <div class="tlw-grid tlw-grid-cols-2 tlw-gap-6 tlw-pt-4">
          <div v-for="product in form.program_products" :key="product.id">
            <ProductOfferCard :product="product" :offers="offers" v-on="$listeners" />
          </div>
          <div
            class="tlw-flex tlw-flex-col tlw-bg-white tlw-shadow-grey tlw-rounded-md tlw-border tlw-border-gray-100 tlw-px-11 tlw-py-12 tlw-items-center tlw-text-center"
          >
            <img class="tlw-mb-6" width="162" height="93" :src="images.addProductIcon" />
            <div class="tlw-text-sm">
              {{
                $t(
                  'sparkaffiliates.programs-list.new-program.program-creator-form.product-form.form-input.additional-info'
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </program-editor-form-layout>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import _ from 'lodash';
import ProgramEditorFormLayout from '@/sparkaffiliates/layouts/ProgramEditorFormLayout.vue';
import ProductOfferCard from './ProductOfferCard';
import MXMultiSelect from '@/shared/components/MXMultiSelect.vue';

export default {
  name: 'ProgramCreateProductsForm',
  components: {
    ProgramEditorFormLayout,
    ProductOfferCard,
    MXMultiSelect,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    offers: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      images: {
        addProductIcon: require('@/assets/images/SparkAffiliates/Programs/add-robot.svg'),
      },
    };
  },
  computed: {
    hasInvalidParams() {
      const { program_products: pp } = this.validation.form;
      return (
        _.keys(pp.$each.$iter).length === 0 &&
        this.form.program_products.some(({ product_offers }) => product_offers.length === 0)
      );
    },
  },
  methods: {
    displayLabel(item) {
      return (
        item.title ||
        this.products.find(
          ({ sparkecommerce_product_id }) => sparkecommerce_product_id === item.sparkecommerce_product_id
        ).title
      );
    },
  },
};
</script>
