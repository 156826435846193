<template>
  <div class="empty-program__container tlw-flex tlw-justify-center">
    <div class="empty-program__content tlw-grid tlw-self-center tlw-justify-items-center tlw-text-center">
      <img class="tlw-pb-10" :src="images.addProgramIcon" />
      <div class="empty-program__title tlw-font-bold tlw-text-2xl tlw-pb-4">
        {{ $t('sparkaffiliates.programs-list.my-programs.empty-list.title') }}
      </div>
      <div class="empty-program__subtitle tlw-pb-6">
        {{ $t('sparkaffiliates.programs-list.my-programs.empty-list.subtitle') }}
      </div>
      <m-button size="sm" variant="primary" @click="goToCreateProgramPage">
        {{ $t('sparkaffiliates.programs-list.my-programs.empty-list.button-text-empty') }}
      </m-button>
    </div>
  </div>
</template>
<script>
import MButton from '@/shared/components/MButton.vue';
export default {
  name: 'EmptyProgram',
  components: {
    MButton,
  },
  data() {
    return {
      images: {
        addProgramIcon: require('@/assets/images/SparkAffiliates/Programs/add-suggestion.svg'),
      },
    };
  },
  methods: {
    goToCreateProgramPage() {
      this.$emit('goToNewProgramPage');
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-program__content {
  width: 408px;
}
</style>
