<template>
  <div class="program-creator-form">
    <section
      data-testid="program-creator-content"
      class="program-creator-form__content tlw-grid tlw-grid-cols-8 tlw-gap-6"
    >
      <div class="program-creator-form__step-menu tlw-col-span-3 md:tlw-col-span-2">
        <div
          class="tlw-flex tlw-flex-col tlw-bg-white tlw-pl-4 tlw-pt-6 tlw-pb-8 tlw-gap-y-2 tlw-sticky tlw-top-20"
          data-testid="program-creator-form-step-menu"
        >
          <div class="tlw-text-sm tlw-font-bold">
            {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.title') }}
          </div>

          <ProgramForm
            :steps="steps"
            :isActive="isActive"
            :isStepDisabled="isStepDisabled"
            :validateClick="validateClick"
          />
        </div>
      </div>
      <div class="tlw-gap-y-6 tlw-col-span-5 md:tlw-col-span-6">
        <component
          ref="tabinstance"
          :is="tabcomponent"
          :form="form"
          :products="products"
          :offers="offers"
          :validation="validation"
          v-on="$listeners"
        />
        <div class="tlw-flex tlw-justify-end">
          <slot name="controls" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import { NewProgramSteps, newProgramStepsOrdered } from '@/libs/new_affiliate_program';
import DefinitionForm from '../ProgramForm/DefinitionForm';
import ProductsForm from '../ProgramForm/ProductsForm';
import CommissionForm from '../ProgramForm/CommissionForm';
import MediaKitForm from '../ProgramForm/MediaKitForm';
import ExpirationDateForm from '../ProgramForm/ExpirationDateForm';
import SummaryPage from '../ProgramForm/SummaryPage';
import { NEW_PROGRAM_TAB } from '@/libs/affiliates';
import { NO_BLOCKING } from '@/mixins/BlockOnLeavingPage';
import ProgramForm from './components/ProgramForm.vue';

export default {
  name: NEW_PROGRAM_TAB,
  components: {
    DefinitionForm,
    ProductsForm,
    CommissionForm,
    MediaKitForm,
    ExpirationDateForm,
    SummaryPage,
    ProgramForm,
  },

  props: {
    step: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
    },
    products: {
      type: Array,
      required: true,
    },
    offers: {
      type: Object,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('school', ['getDefaultDomain']),
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
    tabcomponent() {
      return {
        [NewProgramSteps.DEFINITIONS]: DefinitionForm,
        [NewProgramSteps.PRODUCTS]: ProductsForm,
        [NewProgramSteps.COMMISSION]: CommissionForm,
        [NewProgramSteps.MEDIA_KIT]: MediaKitForm,
        [NewProgramSteps.EXPIRATION_DATE]: ExpirationDateForm,
        [NewProgramSteps.SUMMARY]: SummaryPage,
      }[this.step];
    },

    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
  },
  methods: {
    isActive(step) {
      return this.step === step;
    },
    isStepDisabled(step) {
      return newProgramStepsOrdered(this.step, step);
    },
    mustBlockLeaving() {
      return NO_BLOCKING;
    },
    urlLink(paymentOption) {
      return makeCheckoutUrl(this.hasSparkCheckout, paymentOption, this.product, this.getDefaultDomain);
    },
    validateClick(status) {
      return this.$listeners.goToStep(status);
    },
  },
};
</script>

<style lang="scss" scoped>
.program-creator-form__step {
  color: var(--color-gray-600);
}

.program-creator-form__step--active {
  background-color: var(--color-gray-100);
  color: var(--color-gray-800);
  border-right: 4px solid $cyan;
  cursor: pointer;
}

.program-creator-form__step--deactive {
  cursor: default;
}

.program-creator-form__step--input-valid {
  color: var(--color-gray-800);
}

.program-creator-form__number-circle {
  background-color: var(--color-gray-300);
  color: var(--color-gray-800);
}

.program-creator-form__number-circle--active {
  background-color: $cyan;
}

.program-creator-form__number-circle--input-valid {
  background-color: $green-dark;
  color: $white;
}
</style>
