<template>
  <div class="program-creator-form">
    <hsLoading v-if="isLoading" class="flex-fill" />
    <section v-else data-testid="program-creator-content" class="md:tlw-py-8 md:tlw-px-10 tlw-py-4 tlw-px-6">
      <ProgramEditorForm
        :step="currentStep"
        :steps="steps"
        :form="form"
        :products="products"
        :offers="offers"
        :validation="$v"
        :validateSteps="validateSteps()"
        @goToStep="setSelectedStep"
        @add-media-kit="addMediaKit"
        @selected-offer="manageOffer"
        @loaded-offers="loadedProductOffers"
        @set-product-commission="setProductCommission"
      >
        <template slot="controls">
          <MButton
            v-if="!isFirstStep"
            class="program-form-button__back tlw-w-36 tlw-mt-6 tlw-flex tlw-self-end tlw-mr-4"
            size="md"
            variant="primary-outline"
            @click="goToPreviousStep"
            :label="$t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.previous-step')"
          />

          <MButton
            class="program-form-button__next tlw-w-36 tlw-mt-6 tlw-mr-4 tlw-flex tlw-self-end"
            size="md"
            variant="primary"
            :disabled="!isCurrentStepValid"
            @click="goToNextStep"
            :label="
              isLastStep
                ? 'Salvar'
                : $t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.next-step')
            "
          />
        </template>
      </ProgramEditorForm>
    </section>
    <MConfirmModal
      variant="primary"
      :image="require('@/assets/images/SparkAffiliates/Programs/crazy-high-medium.svg')"
      @confirm="programConfirmed"
      @cancel="programCanceled"
      id="confirm-modal"
      :title="$t('sparkaffiliates.programs-list.new-program.program-creator-form.confirm-program-created.title')"
      :text="$t('sparkaffiliates.programs-list.new-program.program-creator-form.confirm-program-created.text')"
      :confirmButtonLabel="
        $t(
          'sparkaffiliates.programs-list.new-program.program-creator-form.confirm-program-created.confirm-button-label'
        )
      "
      :cancelButtonLabel="
        $t('sparkaffiliates.programs-list.new-program.program-creator-form.confirm-program-created.cancel-button-label')
      "
    />
  </div>
</template>

<script>
import debug from 'debug';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { NewProgramSteps } from '@/libs/new_affiliate_program';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import { NEW_PROGRAM_TAB } from '@/libs/affiliates';
import { NO_BLOCKING } from '@/mixins/BlockOnLeavingPage';
import { BLOCK_ON_PROGRAM_FORM } from '@/libs/program';
import { hsLoading } from '@/components';
import ProgramEditingMixin from '@/sparkaffiliates/mixins/ProgramEditingMixin';
import tracking from '@/shared/helpers/tracking';
import toastHelper from '@/shared/helpers/toast';
import programsService from '@/sparkaffiliates/services/programs.js';
import ProgramEditorForm from '@/sparkaffiliates/views/Producer/ProgramEditorForm';
import MButton from '@/shared/components/MButton.vue';
import MConfirmModal from '@/shared/components/MConfirmModal.vue';

const logging = debug('hs:create-program');

export default {
  name: NEW_PROGRAM_TAB,
  mixins: [ProgramEditingMixin],
  components: {
    hsLoading,
    ProgramEditorForm,
    MButton,
    MConfirmModal,
  },
  data() {
    return {
      logging,
      isLoading: false,
      isSaved: false,
    };
  },
  created() {
    this.getProducts();
  },
  computed: {
    ...mapGetters('school', ['getDefaultDomain']),
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
    tabFromRoute() {
      return NewProgramSteps.DEFINITIONS;
    },
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
  },
  methods: {
    validateSteps() {
      return {
        [NewProgramSteps.DEFINITIONS]: this.validateDefinitions,
        [NewProgramSteps.PRODUCTS]: this.validateProducts,
        [NewProgramSteps.COMMISSION]: this.validateCommissions,
        [NewProgramSteps.MEDIA_KIT]: this.validateMediaKit,
        [NewProgramSteps.EXPIRATION_DATE]: this.validateExpirationDate && this.validateMediaKit,
        [NewProgramSteps.SUMMARY]: this.validateExpirationDate && this.validateMediaKit,
      };
    },
    mustBlockLeaving() {
      return !this.isSaved && this.$v.form.$anyDirty ? BLOCK_ON_PROGRAM_FORM : NO_BLOCKING;
    },
    urlLink(paymentOption, product) {
      return makeCheckoutUrl(this.hasSparkCheckout, paymentOption, product, this.getDefaultDomain);
    },
    setSelectedStep(step) {
      if (this.isNextStep(step.name)) {
        ({
          [NewProgramSteps.DEFINITIONS]: this.trackDefinitions,
          [NewProgramSteps.PRODUCTS]: this.trackProducts,
          [NewProgramSteps.COMMISSION]: this.trackCommission,
          [NewProgramSteps.MEDIA_KIT]: () => {},
          [NewProgramSteps.EXPIRATION_DATE]: this.trackExpirationDate,
          [NewProgramSteps.SUMMARY]: () => {},
        }[this.currentStep]());
      }
      this.currentStep = step;
    },
    goToPreviousStep() {
      const next = {
        [NewProgramSteps.DEFINITIONS]: NewProgramSteps.DEFINITIONS,
        [NewProgramSteps.PRODUCTS]: NewProgramSteps.DEFINITIONS,
        [NewProgramSteps.COMMISSION]: NewProgramSteps.PRODUCTS,
        [NewProgramSteps.MEDIA_KIT]: NewProgramSteps.COMMISSION,
        [NewProgramSteps.EXPIRATION_DATE]: NewProgramSteps.MEDIA_KIT,
        [NewProgramSteps.SUMMARY]: NewProgramSteps.EXPIRATION_DATE,
      }[this.currentStep];
      this.setSelectedStep(next);
    },
    goToNextStep() {
      switch (this.currentStep) {
        case 'definitions':
          this.trackDefinitions();
          break;
        case 'products':
          this.trackProducts();
          break;
        case 'commission':
          this.trackCommission();
          break;
        case 'expiration-date':
          this.trackExpirationDate();
          break;
      }
      const next = {
        [NewProgramSteps.DEFINITIONS]: NewProgramSteps.PRODUCTS,
        [NewProgramSteps.PRODUCTS]: NewProgramSteps.COMMISSION,
        [NewProgramSteps.COMMISSION]: NewProgramSteps.MEDIA_KIT,
        [NewProgramSteps.MEDIA_KIT]: NewProgramSteps.EXPIRATION_DATE,
        [NewProgramSteps.EXPIRATION_DATE]: NewProgramSteps.SUMMARY,
        [NewProgramSteps.SUMMARY]: 'CREATE-PROGRAM',
      }[this.currentStep];
      if (next !== 'CREATE-PROGRAM') {
        return this.setSelectedStep(next);
      }

      this.createProgram();
    },
    trackDefinitions() {
      tracking.affiliationFilledBasicInformation('school id:', this.school.id);
    },
    trackProducts() {
      const products = this.form.program_products;
      _.forEach(products, ({ product_offers, id: product_id }) =>
        _.forEach(product_offers, offer => tracking.affiliationOfferAdded(this.school.id, product_id, offer.id))
      );
    },
    trackCommission() {
      this.form.program_products.forEach(product => {
        const commission = product.commission;
        const product_id = product.product_offers[0].id;
        tracking.affiliationFilledCommission(this.school.id, product_id, commission);
      });
    },
    trackExpirationDate() {
      const expiration = this.form.expiration_date;
      const hasExpiration = expiration ? true : false;
      tracking.affiliationFilledExpirationDate(this.school.id, hasExpiration, expiration);
    },

    createProgram() {
      this.$bvModal.show('confirm-modal');
    },

    async programConfirmed() {
      const { program_products, ...program } = this.form;

      try {
        const products = Object.entries(program_products);
        const { data: newProgram } = await programsService.createProgram(program, products, this.urlLink);

        logging('create program', newProgram);
        tracking.affiliationProgramCreated(
          this.school.id,
          newProgram.id,
          products[0][0],
          parseInt(products[0][1].commission)
        );
        this.isSaved = true;
        toastHelper.successMessage('Programa criado');
        this.$router.push({ name: 'Affiliates', query: { tab: 'InviteAffiliate' } });
      } catch (e) {
        logging('failed to create new program', e, program, program_products);
        toastHelper.dangerMessage('Ocorreu um problema ao criar o programa');
      } finally {
        this.isLoading = false;
      }
    },
    programCanceled() {
      this.$bvModal.hide('confirm-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.program-creator-form__step {
  color: var(--color-gray-600);
}

.program-creator-form__step--active {
  background-color: var(--color-gray-100);
  color: var(--color-gray-800);
  border-right: 4px solid $cyan;
  cursor: pointer;
}

.program-creator-form__step--deactive {
  cursor: default;
}

.program-creator-form__step--input-valid {
  color: var(--color-gray-800);
}

.program-creator-form__number-circle {
  background-color: var(--color-gray-300);
  color: var(--color-gray-800);
}

.program-creator-form__number-circle--active {
  background-color: $cyan;
}

.program-creator-form__number-circle--input-valid {
  background-color: $green-dark;
  color: $white;
}
</style>

<style lang="scss">
.hs-multiselect .multiselect__tag-icon:after {
  content: '\0078' !important;
}
</style>
