<template>
  <hs-table :fields="fields" select-mode="multi" :selectable="false" :responsive="true" :items="affiliationInvites">
    <template slot="image">
      <MAvatar :src="defaultAvatar" class="line-image ml-md-3" />
    </template>

    <template slot="name" slot-scope="row">
      <span class="tlw-text-base line-name">{{ row.item.name }}</span>
      <span class="line-email">{{ row.item.email }}</span>
    </template>

    <template slot="programs" slot-scope="row">
      <span v-if="!Boolean(row.item.program_name)">-</span>
      <div v-else>
        <span class="line-programs">
          {{ row.item.program_name }}
        </span>
      </div>
    </template>

    <template slot="status" slot-scope="row">
      <hs-badge pill variant="warning">
        {{ inviteStatusDictionary[row.item.status] || '-' }}
      </hs-badge>
    </template>

    <template slot="actions" slot-scope="row">
      <div class="d-flex">
        <MButton class="mr-1" size="sm" variant="primary" @click="copyLink(row.item)">Copiar link</MButton>
        <MButton size="sm" variant="primary-outline" @click="resendEmail(row.item)">Reenviar e-mail</MButton>
      </div>
    </template>
  </hs-table>
</template>

<script>
import debug from 'debug';
import Clipboard from '@/shared/mixins/Clipboard';
import { inviteStatusDictionary } from '@/libs/affiliates';
import MButton from '@/shared/components/MButton.vue';
import affiliationInviteService from '@/sparkaffiliates/services/affiliation_invite.js';
import ToastHelper from '@/shared/helpers/toast';
import MAvatar from '@/shared/components/MAvatar.vue';
import { AVATAR_BOT_3 } from '@/libs/avatar';

const logging = debug('hs:affiliation-invites');

const makeField = (key, label, extra = {}) => ({
  key,
  label,
  ...extra,
});

const fields = translate => {
  const sortable = { sortable: true };
  return [
    makeField('image', ''),
    makeField('name', translate('Nome/E-mail'), sortable),
    makeField('programs', translate('Programa'), sortable),
    makeField('status', translate('Status'), sortable),
    makeField('actions', translate('Link de convite'), {}),
  ];
};

export default {
  name: 'AffiliationInvitesList',
  components: { MButton, MAvatar },
  mixins: [Clipboard],
  props: {
    affiliationInvites: { type: Array },
  },
  data() {
    return {
      fields: fields(key => this.$t(key)),
      inviteStatusDictionary,
      defaultAvatar: AVATAR_BOT_3,
    };
  },
  methods: {
    copyLink({ id, link }) {
      const result = this.clipboardCopy(`link-${id}`, link);
      if (result) {
        ToastHelper.successMessage('Link copiado.');
      } else {
        ToastHelper.dangerMessage('Falha ao copiar o link');
      }
    },
    async resendEmail(invite) {
      try {
        await affiliationInviteService.resendEmail(invite.id);
        ToastHelper.successMessage('Convite de afiliação reenviado.');
      } catch (e) {
        logging('failed to resend invite e-mail', e);
        ToastHelper.dangerMessage('Falha ao reenviar o convite de afiliação.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliation-invites-list {
  th {
    color: $grey-ba;
    text-decoration: uppercase;
  }
}

.line {
  background: $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);

  td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &-image {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: $purple-dark;
  }

  &-checkbox {
    margin: 28px 0 28px 32px;
  }

  &-name {
    display: block;
    color: $grey;
    font-size: 14px;
  }

  &-email {
    display: block;
    color: $grey-ba;
    font-size: 14px;
  }

  &-date {
    font-size: 14px;
    color: $grey;
  }

  &-programs {
    color: $grey;
    font-size: 14px;
  }

  &-arrow {
    color: $grey-ba;
  }
}
</style>
