<template>
  <hsLoading v-if="isLoading" />
  <div v-else>
    <EmptyList
      v-if="!hasFilters && !hasAffiliates"
      v-on="$listeners"
      :title="$t('sparkaffiliates.affiliates-list.my-affiliations.empty-list.title')"
      :subtitle="$t('sparkaffiliates.affiliates-list.my-affiliations.empty-list.subtitle')"
      :button-text="$t('sparkaffiliates.affiliates-list.my-affiliations.empty-list.button-text')"
    />
    <div v-else>
      <program-filter
        :nameSearchTerm="nameSearchTerm"
        :sortOptions="sortOptions"
        :sortByValue="sortByValue"
        :programOptions="programOptions"
        :filterByProgram="filterByProgram"
        @input-search-term="filterByText"
        @selected-ordering="filterBySelectedOption"
        @selected-program="filterBySelectedProgram"
        @clear-filter="clearFilter"
        class="pb-5"
      />

      <affiliates-list
        :affiliates="affiliates"
        @selected-affiliate="selectedAffiliation"
        @toggle-activation="toggleActivation"
        class="pt-4"
      />

      <hs-pagination
        v-if="pagination.totalCount > pagination.perPage"
        v-model="pagination.currentPage"
        :go-page-text="$t('sparkmembers.coupons.views.list.index.pagination.go-to-page')"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalCount"
        align="center"
      />
    </div>

    <right-drawer :is-open="drawerIsOpen" @close="closeDrawer">
      <affiliate-details
        :affiliation="currentAffiliation"
        @send-email="sendEmail"
        @delete="openDeletionConfirmationModal"
        @toggle-activation="toggleActivation"
      />
    </right-drawer>

    <ConfirmationModal
      modal-id="delete-affiliation-confirmation"
      :title="$t('sparkaffiliates.affiliates-list.deletion-confirmation-modal.title')"
      :subtitle="$t('sparkaffiliates.affiliates-list.deletion-confirmation-modal.subtitle')"
      :continue-button-text="$t('sparkaffiliates.affiliates-list.deletion-confirmation-modal.continue-button')"
      :cancel-button-text="$t('sparkaffiliates.affiliates-list.deletion-confirmation-modal.secondary-button')"
      :alert-icon="require('@/assets/images/SparkAffiliates/Programs/warning-deletion-robot.svg')"
      continue-button-variant="danger"
      cancel-button-variant="outline-danger"
      @confirmOperation="deleteAffiliation"
    />
  </div>
</template>

<script>
import debug from 'debug';
import { mapState } from 'vuex';
import { hsLoading } from '@/components';
import LoadingMixin from '@/shared/mixins/LoadingMixin';
import PaginationMixin from '@/sparkaffiliates/mixins/PaginationMixin';
import ProgramFilterMixin from '@/sparkaffiliates/mixins/ProgramFilterMixin';
import ToastHelper from '@/shared/helpers/toast';
import affiliationsService from '@/sparkaffiliates/services/affiliations.js';
import EmptyList from '@/sparkaffiliates/views/Producer/AffiliatesPage/components/shared/EmptyList.vue';
import AffiliatesList from '@/sparkaffiliates/views/AffiliatesList.vue';
import { MY_AFFILIATES_TAB } from '@/libs/affiliates';
import ProgramFilter from '@/sparkaffiliates/views/Producer/components/ProgramFilter.vue';
import programsService from '@/sparkaffiliates/services/programs';
import AffiliateDetails from '@/sparkaffiliates/views/drawers/AffiliateDetails.vue';
import RightDrawer from '@/components/RightDrawer.vue';
import { AffiliationStatus } from '@/types/affiliates';
import ConfirmationModal from '@/sparkaffiliates/views/Producer/ProgramPage/components/shared/ConfirmationModal';
import tracking from '@/shared/helpers/tracking';

const logging = debug('hs:affiliates');

export default {
  name: MY_AFFILIATES_TAB,
  mixins: [LoadingMixin, ProgramFilterMixin, PaginationMixin],
  components: {
    hsLoading,
    AffiliateDetails,
    RightDrawer,
    ProgramFilter,
    AffiliatesList,
    EmptyList,
    ConfirmationModal,
  },
  data() {
    return {
      isLoading: false,
      affiliationId: null,
      affiliates: [],
      programs: [],
      defaultSearchParams: {
        status: [AffiliationStatus.ACCEPTED, AffiliationStatus.PAUSED],
      },
    };
  },
  watch: {
    'pagination.currentPage': function(newPage, _oldPage) {
      this.updatePage(newPage);
    },
  },
  created() {
    this.initiateProgramFilter(this.updateAffiliatesFromFilter);
    this.initiatePagination(this.updateAffiliatesFromPagination);
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    currentAffiliation() {
      return this.affiliates.find(({ id }) => id === this.affiliationId) || {};
    },
    drawerIsOpen() {
      return Boolean(this.affiliationId);
    },
    countAffiliates() {
      return this.pagination.totalCount;
    },
    hasAffiliates() {
      return this.pagination.totalCount > 0;
    },
  },
  methods: {
    sendEmail(affiliation) {
      logging('trying to send an e-mail to affiliate', affiliation);
    },
    openDeletionConfirmationModal() {
      this.$bvModal.show('delete-affiliation-confirmation');
    },
    async deleteAffiliation() {
      await affiliationsService.updateProducerAffiliationStatus(this.affiliationId, AffiliationStatus.REMOVED);
      this.closeDrawer();
      this.clearFilter();
    },
    async toggleActivation({ status, affiliation }) {
      try {
        const affiliationId = affiliation.id;
        const toStatus = status ? AffiliationStatus.ACCEPTED : AffiliationStatus.PAUSED;

        const { data: updatedAffiliate } = await affiliationsService.updateProducerAffiliationStatus(
          affiliationId,
          toStatus
        );
        this.affiliates = this.affiliates.reduce(
          (acc, a) => (acc.push(a.id === affiliationId ? { ...a, ...updatedAffiliate } : a), acc),
          []
        );

        (status ? tracking.affiliateAffiliationActivated : tracking.affiliateAffiliationDeactivated)(
          this.selectedSchool.id
        );
        ToastHelper.successMessage(
          this.$t(`sparkaffiliates.programs-list.new-program.affiliates-panel.toast.${status}`)
        );
      } catch (e) {
        ToastHelper.dangerMessage('Ocorreu algum problema ao ativar/desativar afiliado');
      }
    },
    selectedAffiliation(a) {
      this.affiliationId = a.id;
    },
    closeDrawer() {
      this.affiliationId = null;
    },
    clearFilter() {
      this.clearProgramFilter();
      this.updateAffiliatesFromFilter();
    },
    onFailedLoading(e) {
      logging(e);
      ToastHelper.dangerMessage(this.$t('Ocorreu algum problema ao listar os seus afiliados'));
    },
    updateAffiliatesFromFilter() {
      this.loadAffiliates({
        ...this.searchParams,
        ...this.defaultSearchParams,
      }).catch(this.onFailedLoading);
    },
    updateAffiliatesFromPagination() {
      this.loadAffiliates({
        ...this.searchParams,
        ...this.defaultSearchParams,
        page: this.pagination.currentPage,
      }).catch(this.onFailedLoading);
    },
    loadAffiliates(params = {}) {
      return this.withLoading(async () => {
        const { affiliations, ...pagination } = await affiliationsService.getOrganizationAffiliations(params);
        this.affiliates = affiliations;
        this.pagination = pagination;
        logging('loaded %d affiliates', affiliations.length, affiliations);
      });
    },
    updateProgramsFilter() {
      this.programOptions = this.programs.map(program => ({
        id: program.id,
        title: program.name,
      }));
    },
    async loadPrograms(params = {}) {
      const { programs } = await programsService.getOrganizationPrograms(params);
      this.programs = programs;
      logging('loaded %d programs', programs.length, programs);
      this.updateProgramsFilter();
    },
    async fetchData() {
      this.loadAffiliates(this.defaultSearchParams)
        .then(() => this.loadPrograms({ items: 50 }))
        .catch(this.onFailedLoading);
    },
  },
};
</script>
