<template>
  <page-layout>
    <hs-loading v-if="isLoading" />
    <div v-else>
      <div class="mobile-nav h6">Affiliados</div>
      <div class="tlw-p-6 md:tlw-p-10">
        <div class="d-md-none">
          <img class="rocket-img" src="@/assets/images/SparkAffiliates/affiliates/foguete.svg" alt="foguete" />
        </div>
        <div class="tlw-grid tlw-grid-cols-1 custom-grid tlw-gap-4">
          <div class="d-md-none tlw-col-span-6">
            <h3
              class="font-weight-bold tlw-text-xl tlw-leading-6"
              v-html="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.primary.title-mobile')"
            />

            <p
              class="tlw-mt-5 md-mobile-text"
              v-html="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.primary.text-mobile')"
            />
          </div>
          <Card theme="dark" class="tlw-col-span-6 d-none d-md-block card-rocket">
            <h5 class="tlw-text-2xl">
              {{ $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.primary.title') }}
            </h5>
            <p
              class="tlw-mt-4 tlw-w-2/3"
              v-html="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.primary.text')"
            />
          </Card>
          <Card class="tlw-col-span-6 card-horn-head">
            <h5 class="card-title">
              {{ $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.producer.title') }}
            </h5>
            <div class="tlw-mt-4 tlw-mb-6 md:tlw-w-3/4">
              {{ $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.producer.text') }}
            </div>
            <MButton
              id="desktop-producer-btn"
              class="d-none d-md-block"
              size="sm"
              variant="primary"
              @click="enableSparkAffiliatesFeature"
              :label="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.producer.btn')"
            />

            <MButton
              class="d-md-none custom-btn-width"
              size="sm"
              variant="primary"
              @click="openModal"
              :label="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.producer.btn-mobile')"
            />
          </Card>
          <Card class="d-none d-md-block tlw-col-span-3">
            <h5 class="dicas tlw-text-lg"><hs-icon icon="info" /> Dicas importantes</h5>
            <p class="tlw-mt-3">
              {{ $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.tips.title') }}
            </p>
            <text-slider :deck="deck" />
          </Card>
          <Card class="d-none d-md-block tlw-col-span-3 tlw-flex">
            <div class="tlw-text-center tlw-mx-auto tlw-my-auto tlw-flex-1">
              <img :src="require('@/assets/images/SparkAffiliates/graph.png')" />
            </div>
            <p
              class="tlw-text-center"
              v-html="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.illustration.text')"
            />
          </Card>
          <div class="tlw-col-span-6">
            <Card class="card-robot">
              <h5 class="card-title">
                {{ $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.affiliate.title') }}
              </h5>
              <div class="tlw-mt-4 tlw-mb-6 md:tlw-w-3/4">
                {{ $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.affiliate.text') }}
              </div>
              <MButton
                id="desktop-affiliate-btn"
                class="d-none d-md-block"
                size="sm"
                variant="primary"
                @click="enableAffiliationFeature"
                :label="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.affiliate.btn')"
              />
              <MButton
                id="mobile-affiliate-btn"
                class="d-md-none custom-btn-width"
                size="sm"
                variant="primary"
                @click="enableAffiliationFeature"
                :label="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.affiliate.btn-mobile')"
              />
            </Card>
          </div>
          <div class="tlw-col-span-6 d-md-none">
            <Card theme="dark" class="card-robot">
              <h5 class="h4">
                {{ $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.mobile-affiliate.title') }}
                <span class="tlw-text-blue-500"
                  ><br />
                  {{
                    $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.mobile-affiliate.title-color')
                  }}</span
                >
              </h5>
              <div class="tlw-mt-4 tlw-mb-6 md:tlw-w-3/4">
                {{ $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.mobile-affiliate.text') }}
              </div>
              <MButton
                size="sm"
                class="custom-btn-width custom-btn"
                target="blank"
                href="https://herospark.com/blog/category/afiliados/"
                :label="$t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.mobile-affiliate.btn')"
              />
            </Card>
          </div>

          <MobileCarouselModal
            :items="itemsCarousel"
            :maxSlides="3"
            variant="primary"
            @cancel="closeModal"
            @confirm="closeModal"
            id="affiliation-modal"
          />
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import _ from 'lodash';
import debug from 'debug';
import { mapActions, mapGetters, mapState } from 'vuex';
import toastHelper from '@/shared/helpers/toast';
import MButton from '@/shared/components/MButton.vue';
import PageLayout from '@/layout/PageLayout.vue';
import TextSlider from '@/components/TextSlider.vue';
import Card from '@/components/Card.vue';
import accountInitializeService from '@/sparkaffiliates/services/account_initialize';
import organizationService from '@/sparkaffiliates/services/organization.js';
import { hsLoading } from '@/components';
import MobileCarouselModal from '@/sparkaffiliates/components/MobileCarouselModal.vue';

const logging = debug('hs:affiliates');

export default {
  name: 'MainPanel',
  components: {
    MButton,
    PageLayout,
    Card,
    TextSlider,
    hsLoading,
    MobileCarouselModal,
  },
  data() {
    return {
      isLoading: false,
      deck: [
        'Uma opção interessante para quem, está iniciando neste universo de afiliações, é e de tentar sempre diversificar sua carteira e com isso abranger e descobrir quais as melhores oportunidades pode seguir.',
        'Uma opção interessante para quem, está iniciando neste universo de afiliações, é e de tentar sempre diversificar sua carteira e com isso abranger e descobrir quais as melhores oportunidades pode seguir.',
      ],
      itemsCarousel: [
        {
          title: this.$t(
            'landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.mobile-producer-modal.steps.1.title'
          ),
          text: this.$t(
            'landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.mobile-producer-modal.steps.1.text'
          ),
          srcImg: require('@/assets/images/SparkAffiliates/affiliates/step-one-img.svg'),
          btnNext: 'Conhecer mais',
        },
        {
          title: this.$t(
            'landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.mobile-producer-modal.steps.2.title'
          ),
          text: this.$t(
            'landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.mobile-producer-modal.steps.2.text'
          ),
          srcImg: require('@/assets/images/SparkAffiliates/affiliates/step-two-img.svg'),
          btnNext: 'Avançar',
          btnBack: 'Voltar',
        },
        {
          title: this.$t(
            'landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.mobile-producer-modal.steps.3.title'
          ),
          text: this.$t(
            'landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.mobile-producer-modal.steps.3.text'
          ),
          srcImg: require('@/assets/images/SparkAffiliates/affiliates/step-three-img.svg'),
          btnNext: 'Avançar',
          btnBack: 'Voltar',
        },
        {
          title: this.$t(
            'landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.mobile-producer-modal.steps.4.title'
          ),
          text: this.$t(
            'landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.mobile-producer-modal.steps.4.text'
          ),
          srcImg: require('@/assets/images/SparkAffiliates/affiliates/step-four-img.svg'),
          btnNext: 'Começar',
          btnBack: 'Voltar',
        },
      ],
    };
  },
  mounted() {
    const { account_type: platform_name, id: school_id } = this.selectedSchool;
    organizationService.createOrganization({
      core_organization_id: school_id,
      platform_name,
    });
  },
  computed: {
    ...mapGetters('auth', ['fullName']),
    ...mapState('school', ['selectedSchool']),
  },
  methods: {
    ...mapActions('school', ['enableFeature']),
    async enableFeatureAndRedirect(actionName, redirectTo) {
      try {
        this.isLoading = true;

        await accountInitializeService[actionName]();

        const { account_type: platform_name, id: school_id } = this.selectedSchool;
        await organizationService.createOrganization({
          core_organization_id: school_id,
          platform_name,
        });

        this.$router.push({ name: redirectTo });
      } catch (e) {
        logging('failed to enable feature for school', this.selectedSchool, e);
        toastHelper.dangerMessage(
          this.$t('landing-page.views.sparkaffiliates-landing-page.index.toast-messages.feature-activation-error')
        );
      }
      this.isLoading = false;
    },
    enableSparkAffiliatesFeature() {
      const redirectTo = 'ProducerPanel';
      !this.$FCL.hasSparkAffiliates()
        ? this.enableFeatureAndRedirect('tryAddProducerFeatureToSchool', redirectTo)
        : this.$router.push({ name: redirectTo });
    },
    enableAffiliationFeature() {
      const redirectTo = 'MyAffiliations';
      !this.$FCL.isUserAnAffiliate()
        ? this.enableFeatureAndRedirect('tryAddAffiliateFeatureToSchool', redirectTo)
        : this.$router.push({ name: redirectTo });
    },
    openModal() {
      this.$bvModal.show('affiliation-modal');
    },
    closeModal() {
      this.$bvModal.hide('affiliation-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-grid {
  @media screen and (min-width: 770px) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
.card-title {
  font-size: 1rem;
  font-height: 700;
  @media screen and (min-width: 770px) {
    font-size: 1.5rem;
    font-weight: 500;
  }
}
.mobile-nav {
  margin: 0 0 12px 0;
  background: white;
  padding: 20px 38px;
  color: $cherry;
  font-weight: 600;
  padding: 20px 0 21px 31px;
  @media screen and (min-width: 770px) {
    display: none;
  }
}
.custom-btn {
  background: var(--color-blue-500);
}
.custom-btn:hover {
  color: var(--color-gray-800);
}
.custom-btn-width {
  @media screen and (max-width: 770px) {
    width: 100%;
  }
}

.md-mobile-text {
  width: 186px;
  z-index: 20;
  position: relative;
  @media screen and (min-width: 370px) {
    width: 60%;
    margin-bottom: 35px;
  }
}
.rocket-img {
  position: absolute;
  top: 137px;
  right: 0;
  z-index: 8;
}
a {
  color: #212529;
}
</style>
