<template>
  <b-modal
    class="tlw-flex tlw-justify-center affiliates-modal-mobile"
    :size="size"
    hide-footer
    centered
    header-class="pl-3 pl-md-4 border-bottom-0"
    body-class="py-1 px-3 px-md-5"
    v-bind="$attrs"
    @hidden="$emit('cancel')"
  >
    <div>
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="0"
        indicators
        img-height="480"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide v-for="item in items" :key="item.id">
          <template #img>
            <b-container class="d-flex flex-column justify-content-center mb-3 text-center pb-5 carousel-container">
              <div class="d-flex justify-content-center slide-img-container">
                <img :src="item.srcImg" class="slide-img" />
              </div>

              <h3 class="title" v-html="item.title" />
              <p class="text mb-3" v-html="item.text" />

              <div class="afiliates-footer">
                <div class="footer-modal">
                  <div class="d-flex align-items-center pointer  text-purple" v-if="item.btnBack" @click="back">
                    <hs-icon icon="long-arrow-left" class="mr-2 font-weight-bold " />
                    <a id="btnCancel" size="sm" class="btn-back font-weight-bold" v-html="item.btnBack" />
                  </div>

                  <MButton
                    id="btnOk"
                    :variant="confirmButtonVariant"
                    size="sm"
                    :label="item.btnNext"
                    @click="next"
                    class="btn-next"
                  />
                </div>
              </div>
            </b-container>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </b-modal>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  inheritAttrs: false,
  components: {
    MButton,
  },
  props: {
    items: {
      type: Array,
    },
    maxSlides: {
      type: Number,
    },
    variant: {
      type: String,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    confirmButtonVariant() {
      switch (this.variant) {
        case 'delete':
          return 'critical';

        case 'secondary':
          return 'secondary';

        default:
          return 'primary';
      }
    },
    cancelButtonVariant() {
      switch (this.variant) {
        case 'delete':
          return 'critical-outline';

        case 'secondary':
          return 'secondary-outline';

        default:
          return 'primary-outline';
      }
    },
    colorTextVariant() {
      switch (this.variant) {
        case 'delete':
          return 'tlw-text-red-500';

        case 'secondary':
          return 'secondary-outline';

        default:
          return 'primary-outline';
      }
    },
  },

  methods: {
    confirmed() {
      this.$emit('confirm');
      this.$bvModal.hide('confirm-modal');
    },
    cancelled() {
      this.$bvModal.hide('confirm-modal');
      this.$emit('cancel');
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    next() {
      if (this.slide === this.maxSlides) {
        this.$router.push({ name: 'HomePage' });
      } else {
        this.slide++;
      }
    },
    back() {
      this.slide--;
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>

<style lang="scss" scoped>
/deep/.modal-content {
  border-radius: 24px 24px 0 0;
  height: 600px;
  @media screen and (max-width: 300px) {
    height: 630px;
  }
}
.title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 8px;
}
.text {
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
}

/deep/ .m-modal-footer {
  max-width: 330px;
}
/deep/.carousel-indicators {
  margin-top: -80px;

  padding-bottom: 80px;
}
.carousel-container {
  padding: 0 13px;
}
button {
  max-height: 47px;
}
/deep/ .carousel {
  .carousel-indicators {
    position: relative;
    margin-top: -160px;

    li {
      width: 10px;
      height: 10px;
      border: 0;
      background: #7427f1;
      border-radius: 50%;
    }
  }
}
.slide-img {
  max-width: 250px;
  margin: 0 auto 20px;
}
.slide-img-container {
  max-height: 205px;
}

.footer-modal {
  display: flex;
  margin-top: 20px;
  padding: 26px 12px;
}
.btn-back {
  margin-right: auto;
}
.btn-next {
  margin-left: auto;
}
#btnCancel {
  text-decoration: underline !important;
}

#btnOk,
#btnCancel {
  z-index: 40;
}
</style>
