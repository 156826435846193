<template>
  <program-editor-form-layout
    :page-number="3"
    :title="$t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.title')"
    :subtitle="$t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.subtitle')"
  >
    <div class="tlw-bg-white tlw-py-6 tlw-px-8 tlw-mb-6">
      <h3 class="tlw-mb-6 tlw-font-semibold tlw-text-xl">
        Regra de comissionamento
      </h3>
      <div class="tlw-grid tlw-grid-cols-2">
        <div>
          <hs-radio name="commission-rule" v-model="form.commission_type" :value="$options.FIRST_CLICK">
            <p class="radio-label">Por primeiro clique</p>
            <p class="radio-text">A comissão vai para a primeira afiliação que recebeu um clique de compra.</p>
          </hs-radio>
        </div>
        <div>
          <hs-radio name="commission-rule" v-model="form.commission_type" :value="$options.LAST_CLICK">
            <p class="radio-label">Por último clique</p>
            <p class="radio-text">A comissão vai para a última afiliação que recebeu um clique de compra.</p>
          </hs-radio>
        </div>
      </div>
    </div>
    <div class="tlw-flex tlw-flex-col">
      <div class="tlw-w-full tlw-bg-white tlw-p-8">
        <div class="tlw-mb-6 tlw-font-semibold tlw-text-xl">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.form-input.title') }}
        </div>
        <CommissionCard
          v-for="(product, key) in form.program_products"
          :key="key"
          :product="product"
          v-on="$listeners"
        />
      </div>
    </div>
  </program-editor-form-layout>
</template>

<script>
import { FIRST_CLICK, LAST_CLICK } from '@/libs/program';
import ProgramEditorFormLayout from '@/sparkaffiliates/layouts/ProgramEditorFormLayout.vue';
import CommissionCard from './CommissionCard';
import { NewProgramSteps } from '@/libs/new_affiliate_program';

export default {
  name: 'CommissionForm',
  components: {
    CommissionCard,
    ProgramEditorFormLayout,
  },
  FIRST_CLICK,
  LAST_CLICK,
  props: {
    form: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    goToNextStep() {
      this.$emit('goToStep', NewProgramSteps.MEDIA_KIT);
    },
    goToPreviousStep() {
      this.$emit('goToStep', NewProgramSteps.PRODUCTS);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-text {
  color: $color-neutral-600;
}
.radio-text {
  color: $grey;
}
</style>
