<template>
  <div class="tlw-bg-white tlw-shadow-grey tlw-p-6 tlw-rounded-md tlw-border tlw-border-gray-100">
    <div class="tlw-text-sm tlw-font-bold">
      {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.product-form.offer-card.product-name') }}
    </div>
    <div class="tlw-text-sm tlw-mb-6">{{ product.title }}</div>
    <div class="tlw-text-sm tlw-font-bold tlw-mb-2">
      {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.product-form.offer-card.offers') }}
    </div>
    <hs-checkbox
      v-for="offer in offers[productId]"
      :key="offer.id"
      :checked="hasOffer(product.product_offers || [], offer)"
      :id="offer.id.toString()"
      :name="`offer-item-${offer.id}`"
      @input="selectedOffer($event, offer)"
    >
      <div class="tlw-text-sm">
        {{ displayOffer(offer) }}
      </div>
    </hs-checkbox>
  </div>
</template>

<script>
import debug from 'debug';
import { displayOffer } from '@/libs/program';
import toastHelper from '@/shared/helpers/toast';
import programsService from '@/sparkaffiliates/services/programs.js';
import { isOfferEq } from '@/libs/affiliates';

const logging = debug('hs:program-form');

export default {
  name: 'ProductOfferCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
    offers: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.fetchPaymentOptions();
  },
  computed: {
    productId() {
      return this.product.sparkecommerce_product_id || this.product.id;
    },
  },
  methods: {
    selectedOffer(status, offer) {
      this.$emit('selected-offer', { status, productId: this.productId, offer });
    },
    hasOffer(product_offers, offer) {
      return product_offers.some(productOffer => isOfferEq(offer, productOffer));
    },
    displayOffer(offer) {
      return displayOffer(offer);
    },
    async fetchPaymentOptions() {
      try {
        const {
          data: { payment_options: offers },
        } = await programsService.getProductPaymentOptions(this.productId);
        this.$emit('loaded-offers', { productId: this.productId, offers });
      } catch (error) {
        logging('failed to load the offers for:', this.productId, error);
        toastHelper.dangerMessage('Ocorreu um erro ao carregar as ofertas');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .custom-control-input:hover {
  border-color: $purple-dark;
}
/deep/ .custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: $purple-dark;
  background-color: $purple-dark;
}
/deep/ .custom-checkbox:hover .custom-control-input:not(:disabled) ~ .custom-control-label:before {
  border-color: $purple-dark;
}
/deep/.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: $purple-dark;
  background-color: $purple-dark;
}
/deep/.custom-control-input:hover ~ .custom-control-label:before {
  border-color: $purple-dark;
}
/deep/.custom-control-input:not(:focus) ~ .custom-control-label:before {
  border: 1px solid $grey-ba;
}
/deep/.custom-control-input:active ~ .custom-control-label:before {
  background-color: transparent;
  border-color: $purple-dark;
}
</style>
