<template>
  <div>
    <div
      v-for="(step, index) in steps"
      :key="`step-${step.name}`"
      :class="{
        'program-creator-form__step--active tlw-font-bold': isActive(step.name),
        'program-creator-form__step--deactive': isStepDisabled(step.name),
        'program-creator-form__step--input-valid': step.validation(),
      }"
      class="program-creator-form__step tlw-flex tlw-items-center tlw-py-2 tlw-rounded-l-full"
    >
      <div v-if="step.validation()" class="tlw-flex tlw-items-center" @click="validateClick(step.name)">
        <div
          :class="{
            'program-creator-form__number-circle--active': isActive(step.name),
            'program-creator-form__number-circle--input-valid': step.validation(),
          }"
          class="program-creator-form__number-circle tlw-font-semibold tlw-ml-4 tlw-mr-2 tlw-rounded-full tlw-flex tlw-flex-none tlw-justify-center tlw-h-6 tlw-w-6 tlw-items-center"
        >
          <hs-icon icon="check" v-if="step.validation(step.name)" />
          <span v-else>{{ index + 1 }}</span>
        </div>
        <p class="tlw-flex-1 tlw-text-ellipsis tlw-overflow-hidden tlw-m-0">{{ step.label }}</p>
      </div>
      <div v-else class="tlw-flex tlw-items-center">
        <div
          :class="{
            'program-creator-form__number-circle--active': isActive(step.name),
            'program-creator-form__number-circle--input-valid': step.validation(),
          }"
          class="program-creator-form__number-circle tlw-font-semibold tlw-ml-4 tlw-mr-2 tlw-rounded-full tlw-flex tlw-flex-none tlw-justify-center tlw-h-6 tlw-w-6 tlw-items-center"
        >
          <hs-icon icon="check" v-if="step.validation()" />
          <span v-else>{{ index + 1 }}</span>
        </div>
        <p class="tlw-flex-1 tlw-text-ellipsis tlw-overflow-hidden tlw-m-0">{{ step.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramForm',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    isActive: {
      type: Function,
    },
    isStepDisabled: {
      type: Function,
    },
    validateClick: {
      type: Function,
    },
    validateSteps: {
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped>
.program-creator-form__step {
  color: var(--color-gray-600);
}

.program-creator-form__step--active {
  background-color: var(--color-gray-100);
  color: var(--color-gray-800);
  border-right: 4px solid $cyan;
  cursor: pointer;
}

.program-creator-form__step--deactive {
  cursor: default;
}

.program-creator-form__step--input-valid {
  color: var(--color-gray-800);
  cursor: pointer;
}

.program-creator-form__number-circle {
  background-color: var(--color-gray-300);
  color: var(--color-gray-800);
}

.program-creator-form__number-circle--active {
  background-color: $cyan;
}

.program-creator-form__number-circle--input-valid {
  background-color: $green-dark;
  color: $white;
}
</style>
