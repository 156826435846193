<template>
  <page-tab-layout :current-tab="currentTab" :tabs="tabs" @change-tab="changeTab">
    <template slot="header">
      <hsPageHeader
        :title="$t('sparkaffiliates.affiliates-list.header.title')"
        :sub-title="$t('sparkaffiliates.affiliates-list.header.subtitle')"
        :back-text="$t('sparkaffiliates.affiliates-list.header.backtext')"
        back-route="ProducerPanel"
      />
    </template>
    <div data-testid="affiliates-page-content">
      <div class="affiliates-program-list__pages md:tlw-py-8 md:tlw-px-10 tlw-py-4 tlw-px-6">
        <component :is="currentTab" @goToInviteAffiliatePage="goToInviteAffiliatePage" @change-tab="changeTab" />
      </div>
    </div>
  </page-tab-layout>
</template>

<script>
import { mapState } from 'vuex';
import hsPageHeader from '@/components/_structures/PageHeader';
import AffiliatesListTab from '@/sparkaffiliates/views/tabs/AffiliatesListTab.vue';
import AffiliationInvitesTab from '@/sparkaffiliates/views/tabs/InviteAffiliateTab.vue';
import InviteAffiliate from '@/sparkaffiliates/views/InviteAffiliate.vue';
import { MY_AFFILIATES_TAB, INVITE_AFFILIATE_TAB, MY_AFFILIATION_INVITES_TAB } from '@/libs/affiliates';
import PageTabLayout from '@/layout/PageTabLayout.vue';
import tracking from '@/shared/helpers/tracking';

const makeTab = (name, label) => ({
  id: name,
  label,
  component: name,
  permission: false,
});

export default {
  name: 'AffiliatesPage',
  components: {
    PageTabLayout,
    hsPageHeader,
    AffiliatesListTab,
    AffiliationInvitesTab,
    InviteAffiliate,
  },
  data() {
    const params = new URLSearchParams(location.search);
    const currentTab = params.get('tab') || AffiliatesListTab.name;
    return {
      tabs: [
        makeTab(AffiliatesListTab.name, 'Pessoas afiliadas'),
        makeTab(AffiliationInvitesTab.name, 'Convites enviados'),
        makeTab(InviteAffiliate.name, 'Convidar afiliação'),
      ],
      currentTab,
      MY_AFFILIATES_TAB,
      INVITE_AFFILIATE_TAB,
      MY_AFFILIATION_INVITES_TAB,
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    isNotInAddAffiliateTab() {
      return this.currentTab !== INVITE_AFFILIATE_TAB;
    },
  },
  methods: {
    trackInvitationStarted(from) {
      tracking.affiliateInvitationStarted(this.selectedSchool.id, from);
    },
    goToInviteAffiliatePage() {
      this.trackInvitationStarted('button');
      this.changeTab(INVITE_AFFILIATE_TAB);
    },
    changeTab(tab) {
      tab === InviteAffiliate.name && this.trackInvitationStarted('tab');
      this.currentTab = tab;
      this.$router.push({ query: { tab } });
    },
  },
};
</script>
