<template>
  <page-layout>
    <template v-slot:header>
      <hsPageHeader
        :title="$t('sparkaffiliates.producer-panel.header.title')"
        :sub-title="$t('sparkaffiliates.producer-panel.header.subtitle')"
        :back-text="$t('sparkaffiliates.producer-panel.header.backtext')"
        back-route="SparkAffiliates"
      >
        <template slot="actions">
          <m-button size="sm" variant="primary" @click="goToNewProgramPage">
            {{ $t('sparkaffiliates.programs-list.my-programs.empty-list.button-text') }}
          </m-button>
        </template>
      </hsPageHeader>
    </template>
    <div class="tlw-p-10">
      <component
        :is="comp"
        :deck="deck"
        @goToProgramsPage="goToProgramsPage"
        @goToAffiliatesPage="goToAffiliatesPage"
      />
    </div>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import PageLayout from '@/layout/PageLayout.vue';
import hsPageHeader from '@/components/_structures/PageHeader';
import TipsCard from './components/TipsCard';
import ProducerFirstAccess from '@/sparkaffiliates/views/ProducerFirstAccess.vue';
import ProducerDashboard from '@/sparkaffiliates/views/ProducerDashboard.vue';
import { NEW_PROGRAM_TAB } from '@/libs/affiliates';
import MButton from '@/shared/components/MButton.vue';
import tracking from '@/shared/helpers/tracking';

window.firstAccess2 = -1;

export default {
  name: 'ProducerPanel',
  components: {
    MButton,
    hsPageHeader,
    PageLayout,
    TipsCard,
    ProducerFirstAccess,
    ProducerDashboard,
  },
  data() {
    return {
      comp: ProducerFirstAccess,
      deck: [
        'Uma opcao interessante para que, esta iniciando nest universo de afiliacoes, e e de tentar sempre diversificar sua carteira e com isso abranger e descobrir quais as melhores oportunidades pode seguir.',
        'Uma opcao interessante para que, esta iniciando nest universo de afiliacoes, e e de tentar sempre diversificar sua carteira e com isso abranger e descobrir quais as melhores oportunidades pode seguir.',
      ],
    };
  },
  created() {
    this.comp = ++window.firstAccess2 ? ProducerDashboard : ProducerFirstAccess;
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
  },
  methods: {
    goToNewProgramPage() {
      tracking.affiliationNewProgram('button', this.selectedSchool.id);
      this.$router.push({ name: 'ProgramPage', query: { tab: NEW_PROGRAM_TAB } });
    },
    goToProgramsPage() {
      this.$router.push({ name: 'ProgramPage' });
    },
    goToAffiliatesPage() {
      this.$router.push({ name: 'AffiliatesPage' });
    },
  },
};
</script>
