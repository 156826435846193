<template>
  <program-editor-form-layout
    :page-number="4"
    :title="$t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.title')"
    :subtitle="$t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.subtitle')"
  >
    <div class="tlw-flex tlw-flex-col">
      <div class="tlw-w-full tlw-bg-white tlw-p-8">
        <div class="tlw-mb-6 tlw-font-semibold tlw-text-xl">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.form-input.title') }}
        </div>
        <MediaKitCollapse
          v-for="(product, index) in form.program_products"
          class="tlw-mb-6"
          :key="`media-kit-card-${index}`"
          :title="product.title"
          :commission="product.commission"
          :mediaKit="product.media_kits"
          :product="product"
          :collapsed="index < 1"
        >
          <MediaKitProductCard :product="product" :offers="offers" :validation="validation" v-on="$listeners" />
        </MediaKitCollapse>
      </div>
    </div>
  </program-editor-form-layout>
</template>

<script>
import MediaKitCollapse from './MediaKitCollapse';
import ProgramEditorFormLayout from '@/sparkaffiliates/layouts/ProgramEditorFormLayout.vue';
import MediaKitProductCard from './MediaKitProductCard';

export default {
  components: {
    MediaKitCollapse,
    MediaKitProductCard,
    ProgramEditorFormLayout,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    offers: {
      type: Object,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
  },
};
</script>
