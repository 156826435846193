<template>
  <page-tab-layout
    tab-class="affiliations-list__tab"
    :current-tab="selectedTab"
    :tabs="tabs"
    @change-tab="setSelectedTab"
  >
    <template v-slot:header>
      <hsPageHeader
        :title="$t('sparkaffiliates.programs-list.header.title')"
        :sub-title="$t('sparkaffiliates.programs-list.header.subtitle')"
        :back-text="$t('sparkaffiliates.programs-list.header.backtext')"
        back-route="ProducerPanel"
      >
        <template slot="actions">
          <m-button size="sm" variant="primary" @click="goToNewProgramPage">
            {{ $t('sparkaffiliates.programs-list.my-programs.empty-list.button-text') }}
          </m-button>
        </template>
      </hsPageHeader>
    </template>
    <div class="md:tlw-py-8 md:tlw-px-10 tlw-py-4 tlw-px-6">
      <hsLoading v-if="isLoading" />
      <div v-else>
        <div class="affiliates-program-list__pages">
          <component
            ref="tabinstance"
            :is="currentComponent"
            :can-edit="true"
            :programs="allPrograms"
            :program="program"
            :initial-step="atStep"
            @goToNewProgramPage="goToNewProgramPage"
            @goToProgramEdit="goToProgramEdit"
            @goToProgramDetails="goToProgramDetails"
            @returnToProgramList="returnToProgramListPage"
            @returnToProgramListPage="returnToProgramListPage"
          >
            <template v-slot:footer>
              <MButton
                variant="primary"
                size="sm"
                class="tlw-w-72 tlw-mt-6 tlw-flex tlw-self-end"
                @click="returnToProgramListPage"
              >
                {{ $t('sparkaffiliates.programs-list.program-details.definitions.back-to-list') }}
              </MButton>
            </template>
          </component>
        </div>
      </div>
    </div>
    <MConfirmModal
      variant="primary"
      :image="require('@/assets/images/SparkAffiliates/Programs/activation-warning-robot.svg')"
      id="block_on_program_form"
      :title="
        $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.confirmation-modal.title')
      "
      :text="
        $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.confirmation-modal.subtitle')
      "
      :confirmButtonLabel="
        $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.confirmation-modal.continue')
      "
      :cancelButtonLabel="
        $t('landing-page.views.sparkaffiliates-landing-page.index.cards.index.program-page.confirmation-modal.cancel')
      "
      @confirm="leavePage"
      @cancel="cancelBlock"
    />
  </page-tab-layout>
</template>

<script>
import { mapState } from 'vuex';
import { MY_PROGRAMS_TAB, NEW_PROGRAM_TAB, PROGRAM_EDITOR_FORM, PROGRAM_DETAILS_EDITOR } from '@/libs/affiliates';
import { BLOCK_ON_PROGRAM_FORM, BLOCK_ON_PROGRAM_TAB_CHANGED } from '@/libs/program';
import PageTabLayout from '@/layout/PageTabLayout.vue';
import hsPageHeader from '@/components/_structures/PageHeader';
import { hsLoading } from '@/components';
import toastHelper from '@/shared/helpers/toast';
import programsService from '@/sparkaffiliates/services/programs.js';
import NewProgramTab from '@/sparkaffiliates/views/Producer/ProgramCreatorForm';
import ProgramDetailsEditor from '@/sparkaffiliates/components/ProgramDetailsEditor';
import ProgramListTab from '@/sparkaffiliates/views/tabs/ProgramListTab.vue';
import tracking from '@/shared/helpers/tracking';
import BlockOnLeavingPage from '@/mixins/BlockOnLeavingPage';
import ConfirmationModal from '@/sparkaffiliates/views/Producer/ProgramPage/components/shared/ConfirmationModal.vue';
import { NO_BLOCKING } from '@/mixins/BlockOnLeavingPage';
import MButton from '@/shared/components/MButton.vue';
import MConfirmModal from '@/shared/components/MConfirmModal.vue';

const blockOptions = [BLOCK_ON_PROGRAM_FORM, BLOCK_ON_PROGRAM_TAB_CHANGED];

export default {
  name: 'ProgramPage',
  mixins: [BlockOnLeavingPage],
  components: {
    PageTabLayout,
    hsLoading,
    hsPageHeader,
    NewProgramTab,
    ProgramDetailsEditor,
    ProgramListTab,
    ConfirmationModal,
    MButton,
    MConfirmModal,
  },
  data() {
    const params = new URLSearchParams(location.search);
    const selectedTab = params.get('tab') || MY_PROGRAMS_TAB;

    return {
      tabs: [
        { id: MY_PROGRAMS_TAB, component: MY_PROGRAMS_TAB, label: 'Seus programas', permission: false },
        { id: NEW_PROGRAM_TAB, component: NEW_PROGRAM_TAB, label: 'Novo programa', permission: false },
      ],
      isLoading: true,
      program: {},
      selectedTab,
      subview: null,
      allPrograms: [],
      totalCount: 0,
    };
  },
  mounted() {
    this.getAllOrganizationPrograms();
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    atStep() {
      return this.subview ? this.subview[1] : null;
    },
    currentComponent() {
      return this.subview ? this.subview[0] : this.selectedTab;
    },
    myProgramsTab() {
      return MY_PROGRAMS_TAB;
    },
    newProgramTab() {
      return NEW_PROGRAM_TAB;
    },
  },
  methods: {
    mustBlockLeaving() {
      return this.selectedTab === NEW_PROGRAM_TAB ? this.$refs.tabinstance.mustBlockLeaving() : NO_BLOCKING;
    },
    async getAllOrganizationPrograms(params = {}) {
      try {
        this.isLoading = true;

        const programRequest = await programsService.getOrganizationPrograms(params);
        this.allPrograms = programRequest.programs;
        this.totalCount = programRequest.totalCount;
      } catch (e) {
        toastHelper.dangerMessage(this.$t('sparkaffiliates.programs-list.toast.program-page.danger'));
      } finally {
        this.isLoading = false;
      }
    },
    goToNewProgramPage() {
      this.setSelectedTab(this.newProgramTab);
    },
    isActive(tab) {
      return this.selectedTab === tab;
    },
    trackNewProgram() {
      !this.isActive(this.newProgramTab) && tracking.affiliationNewProgram('tab', this.selectedSchool.id);
    },
    changeTab(tab) {
      this.selectedTab = tab;
      this.$router.push({ query: { tab } });
    },
    setSelectedTab(tab) {
      if (this.selectedTab === NEW_PROGRAM_TAB) {
        const mustBlockLeaving = this.mustBlockLeaving();
        if (mustBlockLeaving === BLOCK_ON_PROGRAM_FORM) {
          this.blockBy(BLOCK_ON_PROGRAM_TAB_CHANGED);
          this.saveRoute(tab);
          return;
        }
      }

      if (tab === this.newProgramTab) {
        this.subview = null;
        this.trackNewProgram();
      }

      this.changeTab(tab);
    },
    goToProgramDetails(program) {
      this.subview = [PROGRAM_DETAILS_EDITOR];
      this.program = program;
    },
    goToProgramEdit({ step, program }) {
      this.subview = [PROGRAM_EDITOR_FORM, step || 'definitions'];
      this.program = program;
    },
    async returnToProgramListPage() {
      this.setSelectedTab(this.myProgramsTab);
      this.program = {};
      this.subview = null;
      this.getAllOrganizationPrograms();
    },
    leavePage() {
      if (this.blockedBy === BLOCK_ON_PROGRAM_FORM) {
        this.confirmLeaving();
      }
      if (this.blockedBy === BLOCK_ON_PROGRAM_TAB_CHANGED) {
        this.$bvModal.hide('block_on_program_form');
        const tab = this.saveRouteTo;
        this.changeTab(tab);
      }
    },
    cancelBlock() {
      this.removeBlock();
      this.$bvModal.hide('block_on_program_form');
    },
  },
  watch: {
    blockedBy(n, o) {
      if (!o && blockOptions.includes(n)) {
        this.$bvModal.show('block_on_program_form');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliates-programs-list__tab {
  margin-right: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-gray-800);
}

.affiliates-programs-list__tab--active {
  border-bottom: 4px solid $cyan;
  font-weight: 700;
}
</style>
