
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import MSwitch from '@/components/hsSwitch.vue';
import { AffiliationStatus } from '@/types/affiliates';
import MAvatar from '@/shared/components/MAvatar.vue';
import { AVATAR_BOT_3 } from '@/libs/avatar';
import toastHelper from '@/shared/helpers/toast';

import { ColourByStatus, InviteStatusLabel } from '@/libs/coproduction';

const fields = translate => {
  const sortable = { sortable: true };
  return [
    makeField('image', ''),
    makeField('name', translate('Nome do programa'), sortable),
    makeField('affiliations_count', translate('Afiliações nesse programa'), sortable),
    makeField('activation', translate('Ativação'), sortable),
    makeField('action', ''),
  ];
};
const makeField = (key, label, extra = {}) => ({
  key,
  label,
  ...extra,
});

@Component({
  components: {
    MAvatar,
    MSwitch,
  },
})
export default class ProgramItem extends Vue {
  @Prop({ type: Array }) program!: any[];
  @Prop({ type: Boolean }) checked!: any;

  data() {
    return {
      fields: fields(key => this.$t(key)),
    };
  }

  defaultAvatar = AVATAR_BOT_3;

  selectedProgram(program) {
    this.$emit('selected-program', program);
  }
  toggleActivation(status, program) {
    this.$emit('toggle-activation', {
      status,
      program,
    });
  }
}
