<template>
  <div>
    <div class="program-list__inputs tlw-flex tlw-flex-row tlw-mb-6">
      <div class="program-list__textual-search tlw-mr-14">
        <label>Buscar</label>
        <hs-input
          id="programSearch"
          type="search"
          placeholder="O que você está procurando?"
          v-model="nameSearchTerm"
          @input="searchPrograms"
        />
      </div>
      <div class="program-list__order-by tlw-mr-14">
        <label>Ordenar por</label>
        <hs-multiselect
          placeholder="Ordenar por"
          track-by="id"
          label="title"
          :options="sortByOptions"
          :showLabels="true"
          :searchable="false"
          v-model="sortByValue"
          :multiple="false"
          :allow-empty="false"
          @select="getFilteredPrograms"
        />
      </div>
      <div class="program-list__filter-by tlw-mr-4">
        <label>Filtrar por</label>
        <hs-multiselect
          placeholder="Programas cadastrados"
          track-by="id"
          label="title"
          :options="filterByOptions"
          :showLabels="true"
          :searchable="false"
          v-model="filterByValue"
          :multiple="false"
          :allow-empty="true"
          @select="getFilteredProgramsBySelect"
        />
      </div>
      <div class="tlw-self-end tlw-cursor-pointer">
        <MButton
          id="clearFilter"
          variant="secondary"
          :label="$t('components.filters.selected-filters.clear-filters')"
          @click="clearFilters"
        />
      </div>
    </div>
    <hsLoading v-if="isLoading" />
    <div data-testid="program-list-content" class="pb-5" v-else>
      <div
        class="tlw-flex pt-3 tlw-text-gray-500 tlw-text-sm tlw-flex-row tlw-items-center tlw-w-full tlw-pb-8 md:tlw-justify-items-start tlw-justify-items-center md:tlw-text-left tlw-text-center"
      >
        <div v-if="hasSelectedItems">
          <MButton
            label="Fechar"
            id="all-programs"
            variant="primary-outline"
            :checked="selectedAllCheckboxStatus"
            name="program-list"
            @click="selectAll"
          />
        </div>
        <div
          v-if="!hasSelectedItems"
          class="tlw-text-sm tlw-flex tlw-gap-x-11 tlw-flex-row tlw-items-center tlw-w-full tlw-rounded-lg  md:tlw-justify-items-start tlw-justify-items-center md:tlw-text-left tlw-text-center"
        >
          <MButton
            label="Ações avançado"
            id="all-programs"
            variant="primary-outline"
            :checked="selectedAllCheckboxStatus"
            name="program-list"
            @click="selectAll"
          />
        </div>
        <div v-else class="tlw-flex tlw-flex-row ml-4 tlw-gap-x-12 tlw-items-center">
          <div class="tlw-flex tlw-gap-x-3 tlw-flex-row">
            {{ disableAllPrograms ? 'Desativar todos os programas' : 'Ativar todos os programas' }}
            <HsSwitch v-model="disableAllPrograms" @change="openProgramEnablementModal" />
          </div>
          <hs-button variant="danger" @click="showDeletionAllConfirmationModal">
            Excluir todos os programas selecionados
          </hs-button>
        </div>
      </div>

      <ProgramItem
        :program="allFilteredPrograms"
        @toggle-activation="updateProgramStatus"
        @checked-program="checkProgram"
        @selected-program="selectedProgram"
      />

      <hs-pagination
        v-if="pagination.totalCount > pagination.perPage"
        v-model="pagination.currentPage"
        :total-rows="pagination.totalCount"
        go-page-text="Próxima página"
        :per-page="pagination.perPage"
        align="center"
      />
    </div>
    <right-drawer :is-open="hasSelectedProgram" @close="clearSelectedProgram">
      <program-details
        :can-edit="true"
        :created-at-title="$t('sparkaffiliates.programs-list.sidebar.created-at')"
        :created-at-value="programCreatedAt"
        :activation-title="$t('sparkaffiliates.programs-list.sidebar.program')"
        :getActiveProductsName="getActiveProductsName"
        sidebar-id="program-info"
        :program="currentProgram"
        v-on="$listeners"
        @toggle-activation="updateProgramStatus"
        @delete="showDeletionConfirmationModal"
      >
        <template v-slot:top>
          <hs-badge class="tlw-w-min tlw-mb-4" variant="purple" pill>
            {{ countAffiliations }}
            {{ $t('sparkaffiliates.programs-list.sidebar.affiliations') }}
          </hs-badge>
        </template>
      </program-details>
    </right-drawer>
    <DeletionConfirmationSidebarModal
      sidebar-id="program-deletion-modal"
      @confirm="deleteOpenProgram"
      @cancel="closeDeletionConfirmationModal"
    />
    <ConfirmationModal
      modal-id="program-all-deletion-modal"
      :title="$t('sparkaffiliates.programs-list.my-programs.deletion-alert-modal.title')"
      :subtitle="$t('sparkaffiliates.programs-list.my-programs.deletion-alert-modal.subtitle')"
      :continue-button-text="$t('sparkaffiliates.programs-list.my-programs.deletion-alert-modal.continue-button')"
      :cancel-button-text="$t('sparkaffiliates.programs-list.my-programs.deletion-alert-modal.secondary-button')"
      :alert-icon="require('@/assets/images/SparkAffiliates/Programs/warning-deletion-robot.svg')"
      continue-button-variant="danger"
      cancel-button-variant="outline-danger"
      @confirmOperation="deleteCheckedPrograms"
      @undoToggleChange="closeDeletionAllConfirmationModal"
    />
    <ConfirmationModal
      modal-id="program-activation-alert"
      :title="$t('sparkaffiliates.programs-list.my-programs.activation-alert-modal.title')"
      :subtitle="$t('sparkaffiliates.programs-list.my-programs.activation-alert-modal.subtitle')"
      :continue-button-text="$t('sparkaffiliates.programs-list.my-programs.activation-alert-modal.continue-button')"
      :cancel-button-text="$t('sparkaffiliates.programs-list.my-programs.activation-alert-modal.secondary-button')"
      @confirmOperation="updateStatusInBatch(true)"
      @undoToggleChange="undoToggleChange"
    />
    <ConfirmationModal
      modal-id="program-deactivation-alert"
      :title="$t('sparkaffiliates.programs-list.my-programs.deactivation-alert-modal.title')"
      :subtitle="$t('sparkaffiliates.programs-list.my-programs.deactivation-alert-modal.subtitle')"
      :continue-button-text="$t('sparkaffiliates.programs-list.my-programs.deactivation-alert-modal.continue-button')"
      :cancel-button-text="$t('sparkaffiliates.programs-list.my-programs.deactivation-alert-modal.secondary-button')"
      continue-button-variant="danger"
      cancel-button-variant="outline-danger"
      @confirmOperation="updateStatusInBatch(false)"
      @undoToggleChange="undoToggleChange"
    />
  </div>
</template>

<script>
import debug from 'debug';
import dayjs from 'dayjs';
import _ from 'lodash';
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import programsService from '@/sparkaffiliates/services/programs.js';
import toastHelper from '@/shared/helpers/toast';
import { mapActions } from 'vuex';
import { hsLoading } from '@/components';
import ProgramItem from '@/sparkaffiliates/views/ProgramItem';
import ConfirmationModal from '@/sparkaffiliates/views/Producer/ProgramPage/components/shared/ConfirmationModal';
import PaginationMixin from '@/sparkaffiliates/mixins/PaginationMixin';
import RightDrawer from '@/components/RightDrawer.vue';
import DeletionConfirmationSidebarModal from '@/sparkaffiliates/views/Producer/ProgramPage/components/DeletionConfirmationSidebarModal.vue';
import ProgramDetails from '@/sparkaffiliates/views/drawers/ProgramDetails.vue';
import tracking from '@/shared/helpers/tracking';
import HsSwitch from '@/components/hsSwitch.vue';
import MButton from '@/shared/components/MButton';

const logging = debug('hs:program-list');

export default {
  mixins: [PaginationMixin],
  name: 'ProgramList',
  components: {
    hsLoading,
    ProgramItem,
    RightDrawer,
    ProgramDetails,
    DeletionConfirmationSidebarModal,
    ConfirmationModal,
    HsSwitch,
    MButton,
  },
  data() {
    return {
      disableAllPrograms: true,
      programId: null,
      allFilteredPrograms: [],
      selectedListItems: new Set(),
      isLoading: false,
      sortByValue: { id: 'created-at-desc', title: 'Programas recentes (decrescente)' },
      hasCheckedProgram: false,
      nameSearchTerm: null,
      filterByValue: null,
      sortByOptions: [
        { id: 'name-asc', title: 'Nome do programa (A a Z)' },
        { id: 'name-desc', title: 'Nome do programa (Z a A)' },
        { id: 'created-at-asc', title: 'Programas recentes (crescente)' },
        { id: 'created-at-desc', title: 'Programas recentes (decrescente)' },
        { id: 'affiliation-count-asc', title: 'Número de Afiliações (menor a maior)' },
        { id: 'affiliation-count-desc', title: 'Número de Afiliações (maior a menor)' },
        { id: 'last-sale-asc', title: 'Última venda (crescente)' },
        { id: 'last-sale-desc', title: 'Última venda (decrescente)' },
        { id: 'enabled-asc', title: 'Status de ativação (desativada - ativa)' },
        { id: 'enabled-desc', title: 'Status de ativação (ativa - desativada)' },
      ],
      filterByOptions: [],
      sortParamsDictionary: {
        'name-asc': { sort_by: 'name' },
        'name-desc': { sort_by: 'name', order: 'DESC' },
        'created-at-asc': { sort_by: 'created_at' },
        'created-at-desc': { sort_by: 'created_at', order: 'DESC' },
        'affiliation-count-asc': { sort_by: 'affiliation_count' },
        'affiliation-count-desc': { sort_by: 'affiliation_count', order: 'DESC' },
        'enabled-asc': { sort_by: 'enabled' },
        'enabled-desc': { sort_by: 'enabled', order: 'DESC' },
      },
    };
  },
  async created() {
    this.searchPrograms = debounce(async searchTerm => {
      this.nameSearchTerm = searchTerm;
      this.filterByValue = null;
      this.getFilteredPrograms();
    }, 300);
    this.getFilteredPrograms = debounce(this.getFilteredPrograms, 300);
    this.getFilteredProgramsBySelect = debounce(this.getFilteredProgramsBySelect, 300);
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    hasSelectedItems() {
      return this.selectedListItems.size > 0;
    },
    selectedAllCheckboxStatus() {
      return this.selectedListItems.size === this.allFilteredPrograms.length;
    },

    programCreatedAt() {
      return this.programId ? dayjs(this.currentProgram.created_at).format('DD/MM/YYYY') : '';
    },
    hasSelectedProgram() {
      return Boolean(this.programId);
    },
    currentProgram() {
      return this.allFilteredPrograms.find(({ id }) => id === this.programId) || {};
    },
    countAffiliations() {
      return this.currentProgram.affiliations_count;
    },
  },
  watch: {
    'pagination.currentPage': function(newPage, oldPage) {
      this.selectedListItems = new Set();
      this.getFilteredPrograms();
    },
  },
  async mounted() {
    await this.initializeVariables();
  },
  methods: {
    ...mapActions('product', ['getProduct']),
    batchApply(f) {
      return _.reduce(
        Array.from(this.selectedListItems),
        (p, programId) => p.then(() => f(programId)).catch(x => x),
        Promise.resolve()
      );
    },
    async getActiveProductsName(program) {
      if (_.isEmpty(program)) {
        return;
      }
      const activeProducts = program.program_products.map(async product => {
        const productInfo = await this.getProduct({ id: product.sparkecommerce_product_id });

        return productInfo.title;
      });

      return await Promise.all(activeProducts);
    },
    async updateProgramStatus({ status, program }) {
      try {
        const programId = program.id;
        const action = status ? programsService.enableProgram : programsService.disableProgram;
        const { data: updatedProgram } = await action(programId);
        this.allFilteredPrograms = this.allFilteredPrograms.reduce(
          (acc, p) => (acc.push(p.id === programId ? { ...p, ...updatedProgram } : p), acc),
          []
        );
        (status ? tracking.affiliationProgramActivated : tracking.affiliationProgramDeactivated)(
          this.selectedSchool.id,
          programId
        );
        toastHelper.successMessage(
          this.$t(`sparkaffiliates.programs-list.new-program.affiliates-panel.toast.${status}`)
        );
      } catch (e) {
        toastHelper.dangerMessage('Ocorreu algum problema ao ativar/desativar programa');
      }
    },
    async getFilteredProgramsBySelect() {
      this.nameSearchTerm = this.filterByValue.title;
      await this.getFilteredPrograms();
    },
    async getFilteredPrograms() {
      await this.getAllOrganizationPrograms(this.searchParams());
    },
    async getAllOrganizationPrograms(params = {}) {
      this.isLoading = true;

      try {
        const programRequest = await programsService.getOrganizationPrograms(params);
        this.setFilterVariables(programRequest);
      } catch (e) {
        toastHelper.dangerMessage('Ocorreu algum problema ao listar os seus programas');
      }

      this.isLoading = false;
    },
    async clearFilters() {
      this.nameSearchTerm = null;
      this.filterByValue = null;
      this.pagination.currentPage = 1;
      this.sortByValue = { id: 'created-at-desc', title: 'Programas recentes (decrescente)' };

      await this.getFilteredPrograms();
    },
    async sortByHeaderClick(sortItemName) {
      if (this.sortByValue.id.includes(sortItemName)) {
        if (this.sortByValue.id.includes('asc')) {
          this.sortByValue.id = `${sortItemName}-desc`;
        } else {
          this.sortByValue.id = `${sortItemName}-asc`;
        }
      } else {
        this.sortByValue.id = `${sortItemName}-asc`;
      }

      this.sortByValue.title = 'Ordenar por';

      await this.getFilteredPrograms();
    },
    async updateStatusInBatch(status) {
      await this.batchApply(programId =>
        this.updateProgramStatus({
          status,
          program: { id: parseInt(programId) },
        }).catch(e => logging('failed to update status of program:', programId, e))
      );
    },
    async deleteOpenProgram() {
      try {
        await this.deleteProgram(this.programId);
        this.programId = null;
        this.closeDeletionConfirmationModal();
      } catch (e) {
        logging('failed to delete program:', this.programId, e);
      }
    },
    async deleteCheckedPrograms() {
      try {
        await this.batchApply(programId =>
          this.deleteProgram(programId).catch(e => logging('failed to delete program:', programId, e))
        );

        this.closeDeletionConfirmationModal();
        this.selectedListItems = new Set();

        await this.getFilteredPrograms();
      } catch (e) {} // eslint-disable-line no-empty
    },
    async deleteProgram(programId) {
      try {
        await programsService.deleteProgram(programId);
        await this.getFilteredPrograms();
      } catch (e) {
        toastHelper.dangerMessage('Ocorreu algum problema ao deletar o programa');
        throw e;
      }
    },
    clearSelectedProgram() {
      this.programId = null;
    },
    showDeletionConfirmationModal() {
      this.$bvModal.show('program-deletion-modal');
    },
    closeDeletionConfirmationModal() {
      this.$bvModal.hide('program-deletion-modal');
    },
    showDeletionAllConfirmationModal() {
      this.$bvModal.show('program-all-deletion-modal');
    },
    closeDeletionAllConfirmationModal() {
      this.$bvModal.hide('program-all-deletion-modal');
    },
    confirmProgramDeletion() {
      this.deleteProgram(this.programId).then(() => {
        this.closeDeletionConfirmationModal();
        this.clearSelectedProgram();
      });
    },
    undoToggleChange() {
      this.$bvModal.hide('program-activation-alert');
      this.$bvModal.hide('program-deactivation-alert');
    },
    openProgramEnablementModal() {
      const open = !this.disableAllPrograms ? 'program-deactivation-alert' : 'program-activation-alert';
      this.$bvModal.show(open);
    },
    selectAll() {
      let update;
      if (this.selectedAllCheckboxStatus) {
        update = [];
      } else {
        update = this.allFilteredPrograms.map(({ id }) => id);
      }
      this.selectedListItems = new Set(update);
    },
    checkProgram({ program }) {
      const action = this.selectedListItems.has(program.id) ? 'delete' : 'add';
      this.selectedListItems[action](program.id);
      this.selectedListItems = new Set(this.selectedListItems);
    },
    selectedProgram(program) {
      this.programId = program.id;
    },
    searchParams() {
      return {
        name: this.nameSearchTerm,
        page: this.pagination.currentPage,
        ...this.paramsBySortKey(),
      };
    },
    chevronIconBySort(sortItemName) {
      if (this.sortByValue.id.includes(`${sortItemName}-asc`)) {
        return 'chevron-up';
      }

      return 'chevron-down';
    },
    async initializeVariables() {
      try {
        const programRequest = await programsService.getOrganizationPrograms(this.searchParams());
        this.setFilterVariables(programRequest);

        this.filterByOptions = this.allFilteredPrograms.map(program => ({
          id: program.id,
          title: program.name,
        }));
      } catch (error) {
        logging('failed to load the programs', error);
        toastHelper.dangerMessage('Ocorreu algum problema ao listar os seus programas');
      }
    },
    paramsBySortKey() {
      return this.sortParamsDictionary[this.sortByValue.id];
    },
    setFilterVariables(programRequest) {
      this.allFilteredPrograms = programRequest.programs;
      this.pagination.currentPage = programRequest.currentPage;
      this.pagination.totalCount = programRequest.totalCount;
      this.pagination.perPage = programRequest.perPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.program-list__header-name {
  flex: 0 0 22%;
}

.program-list__header-affiliation {
  flex: 0 0 21%;
}

.program-list__header-last-sale {
  flex: 0 0 14%;
}

.program-list__header-enabled {
  flex: 0 0 10%;
}

.program-list__textual-search {
  flex: 0 0 25%;
}

.program-list__order-by {
  flex: 0 0 25%;
}

.program-list__filter-by {
  flex: 0 0 25%;
}
</style>
