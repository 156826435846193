<template>
  <div class="mx-multiselect">
    <div class="mx-multiselect__control" @click="toggleDropdown" :class="{ 'mx-multiselect__control--active': isOpen }">
      <div class="mx-multiselect__tags">
        <div v-if="taggable" class="mx-multiselect__search-wrapper">
          <input
            type="text"
            v-model="search"
            :placeholder="searchPlaceholder"
            @keyup.enter="handleTag"
            @keydown.tab.prevent="handleTag"
            @blur="onBlur"
            class="mx-multiselect__search-input"
          />
        </div>
        <template v-if="multiple">
          <span v-for="option in selectedOptions" :key="getOptionKey(option)" class="mx-multiselect__tag">
            <span>{{ customLabel ? customLabel(option) : getOptionLabel(option) }}</span>
            <i class="mx-multiselect__tag-remove" @click.stop="removeOption(option)">&times;</i>
          </span>
        </template>
        <span v-else-if="!multiple && value" class="mx-multiselect__single">
          {{ customLabel ? customLabel(value) : getOptionLabel(value) }}
        </span>
        <span v-else class="mx-multiselect__placeholder">{{ placeholder }}</span>
      </div>
      <div class="mx-multiselect__arrow"></div>
    </div>

    <div v-show="isOpen" class="mx-multiselect__dropdown">
      <ul class="mx-multiselect__options">
        <li
          v-for="option in filteredOptions"
          :key="getOptionKey(option)"
          class="mx-multiselect__option"
          :class="{ 'mx-multiselect__option--selected': isSelected(option) }"
          @click="selectOption(option)"
        >
          {{ customLabel ? customLabel(option) : getOptionLabel(option) }}
        </li>
        <li
          v-if="taggable && search && !hasExactMatch"
          class="mx-multiselect__option mx-multiselect__option--create"
          @click="handleTag"
        >
          Criar "{{ search }}"
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MXMultiSelect',
  components: {},
  props: {
    value: {
      type: [Array, Object, String, Number],
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Selecione uma opção',
    },
    searchPlaceholder: {
      type: String,
      default: 'Digite para buscar',
    },
    customLabel: {
      type: Function,
      default: null,
    },
    label: {
      type: String,
      default: 'name',
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      search: '',
      selectedOptions: Array.isArray(this.value) ? this.value : [],
    };
  },
  computed: {
    filteredOptions() {
      if (!this.search) return this.options;

      return this.options.filter(option =>
        this.getOptionLabel(option)
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
    hasExactMatch() {
      return this.options.some(option => this.getOptionLabel(option).toLowerCase() === this.search.toLowerCase());
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    getOptionLabel(option) {
      if (typeof option === 'object') {
        return option[this.label] || option;
      }
      return option;
    },
    getOptionKey(option) {
      if (typeof option === 'object') {
        return option[this.trackBy] || option;
      }
      return option;
    },
    isSelected(option) {
      if (this.multiple) {
        return this.selectedOptions.some(selected => this.getOptionKey(selected) === this.getOptionKey(option));
      }
      return this.value && this.getOptionKey(this.value) === this.getOptionKey(option);
    },
    selectOption(option) {
      if (this.multiple) {
        const index = this.selectedOptions.findIndex(
          selected => this.getOptionKey(selected) === this.getOptionKey(option)
        );

        if (index === -1) {
          this.selectedOptions.push(option);
        } else {
          this.selectedOptions.splice(index, 1);
        }

        this.$emit('input', this.selectedOptions);
        this.$emit('select', option);
      } else {
        this.$emit('input', option);
        this.$emit('select', option.type || option);
        this.isOpen = false;
      }
    },
    removeOption(option) {
      const index = this.selectedOptions.findIndex(
        selected => this.getOptionKey(selected) === this.getOptionKey(option)
      );

      if (index !== -1) {
        this.selectedOptions.splice(index, 1);
        this.$emit('input', this.selectedOptions);
      }
    },
    handleTag() {
      if (this.taggable && this.search.trim()) {
        this.$emit('tag', this.search.trim());
        this.search = '';
        this.isOpen = false;
      }
    },
    onBlur() {
      setTimeout(() => {
        this.$emit('blur');
        this.isOpen = false;
      }, 200);
    },
    handleClickOutside(event) {
      const select = this.$el;
      if (!select.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (this.multiple) {
          this.selectedOptions = Array.isArray(newValue) ? newValue : [];
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss">
.mx-multiselect {
  position: relative;
  width: 100%;

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    padding: 8px 12px;
    border: 1px solid #7427f1;
    border-radius: 4px;
    cursor: pointer;
    background: #fff;

    &--active {
      border-color: #7427f1;
      box-shadow: 0 0 0 1px #7427f1;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    flex: 1;
  }

  &__tag {
    display: inline-flex;
    align-items: center;
    background: #ead3fe;
    color: #7427f1;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.875rem;

    &-remove {
      margin-left: 4px;
      cursor: pointer;
      font-style: normal;

      &:hover {
        color: #4d1aa1;
      }
    }
  }

  &__placeholder {
    color: #bababa;
    font-size: 0.875rem;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: #fff;
    border: 1px solid #7427f1;
    border-radius: 4px;
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
  }

  &__search-wrapper {
    flex: 1;
    min-width: 100px;
  }

  &__search-input {
    width: 100%;
    border: none;
    outline: none;
    padding: 4px;
    font-size: 0.875rem;
    background: transparent;

    &::placeholder {
      color: #bababa;
    }
  }

  &__options {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__option {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 0.875rem;

    &:hover {
      background-color: #ead3fe;
    }

    &--selected {
      background-color: #7427f1;
      color: #fff;

      &:hover {
        background-color: #4d1aa1;
      }
    }

    &--create {
      color: #7427f1;
      font-style: italic;
    }
  }

  &__arrow {
    border: solid #7427f1;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    margin-left: 8px;
  }
}
</style>
