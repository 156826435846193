import _ from 'lodash';
import { required, between, url } from 'vuelidate/lib/validators';
import { LAST_CLICK } from '@/libs/program';
import toastHelper from '@/shared/helpers/toast';
import programsService from '@/sparkaffiliates/services/programs.js';
import {
  NewProgramSteps,
  newProgramStepsIndex,
  isNewProgramStepsLast,
  newProgramStepsOrdered,
  isProgramStepNext,
} from '@/libs/new_affiliate_program';
import { isOfferEq } from '@/libs/affiliates';

export default {
  validations: {
    form: {
      name: {
        required,
      },
      description: {
        required,
      },
      rules: {},
      expiration_date: {},
      commission_type: { required },
      program_products: {
        required,
        $each: {
          commission: {
            between: between(0, 100),
          },
          product_offers: {
            required,
            $each: { required },
          },
          media_kits: {
            required,
            $each: {
              required,
              url: {
                required,
                url,
              },
            },
          },
        },
      },
    },
  },
  data() {
    const self = this;
    return {
      currentStep: this.tabFromRoute || NewProgramSteps.DEFINITIONS,
      products: [],
      offers: {},
      form: {
        name: '',
        description: '',
        rules: '',
        commission_type: LAST_CLICK,
        program_products: [],
        expiration_date: null,
      },
      numberOfProducts: 0,
      steps: [
        {
          name: NewProgramSteps.DEFINITIONS,
          validation: () => {
            return self.validateSteps()[NewProgramSteps.DEFINITIONS];
          },
          label: this.$t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.definition'),
        },
        {
          name: NewProgramSteps.PRODUCTS,
          validation: () => self.validateSteps()[NewProgramSteps.PRODUCTS],
          label: this.$t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.products'),
        },
        {
          name: NewProgramSteps.COMMISSION,
          validation: () => self.validateSteps()[NewProgramSteps.COMMISSION],
          label: this.$t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.commission'),
        },
        {
          name: NewProgramSteps.MEDIA_KIT,
          validation: () => self.validateSteps()[NewProgramSteps.MEDIA_KIT],
          label: this.$t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.media-kit'),
        },
        {
          name: NewProgramSteps.EXPIRATION_DATE,
          validation: () => self.validateSteps()[NewProgramSteps.EXPIRATION_DATE],
          label: this.$t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.expiration-date'),
        },
        {
          name: NewProgramSteps.SUMMARY,
          validation: () => self.validateSteps()[NewProgramSteps.SUMMARY],
          label: this.$t('sparkaffiliates.programs-list.new-program.program-creator-form.wizard-steps.summary'),
        },
      ],
    };
  },
  computed: {
    isFirstStep() {
      return newProgramStepsIndex(this.currentStep) === 0;
    },
    isLastStep() {
      return isNewProgramStepsLast(this.currentStep);
    },
    validateDefinitions() {
      const { form } = this.$v;
      return !form.name.$invalid && !form.description.$invalid;
    },
    validateProducts() {
      const { form } = this.$v;
      const { program_products: pp } = form;
      return (
        _.keys(pp.$each.$iter).length > 0 &&
        this.form.program_products.every(({ product_offers }) => (product_offers || []).length > 0)
      );
    },
    validateCommissions() {
      const products = this.form.program_products;
      return !_.isEmpty(products) && !_.some(products, ({ commission }) => !commission || !between(0, 100)(commission));
    },
    validateMediaKit() {
      const products = this.form.program_products;
      return (
        !_.isEmpty(products) &&
        !_.some(
          products,
          product => !product.media_kits || product.media_kits.length === 0 || product.media_kits[0].source == null
        )
      );
    },
    validateExpirationDate() {
      return true;
    },
    validateSummary() {
      return true;
    },
    isCurrentStepValid() {
      return this.validateSteps()[this.currentStep];
    },
  },
  methods: {
    isStepDisabled(step) {
      return newProgramStepsOrdered(this.currentStep, step);
    },
    isNextStep(step) {
      return isProgramStepNext(this.currentStep, step);
    },
    isActive(step) {
      return this.currentStep === step;
    },
    setSelectedStep(step) {
      if (this.isNextStep(step)) {
        ({
          [NewProgramSteps.DEFINITIONS]: this.trackDefinitions,
          [NewProgramSteps.PRODUCTS]: this.trackProducts,
          [NewProgramSteps.COMMISSION]: this.trackCommission,
          [NewProgramSteps.MEDIA_KIT]: () => {},
          [NewProgramSteps.EXPIRATION_DATE]: this.trackExpirationDate,
          [NewProgramSteps.SUMMARY]: () => {},
        }[this.currentStep]());
      }

      this.currentStep = step;
    },
    loadedProductOffers({ productId, offers }) {
      this.logging('loaded offers of product', productId, offers);
      this.$set(this.offers, productId, offers);
    },
    updateProduct(productId, f) {
      return this.form.program_products.reduce((acc, product) => {
        if (productId === product.sparkecommerce_product_id) {
          f(product);
        }
        acc.push({ ...product });
        return acc;
      }, []);
    },
    setProductCommission({ productId, commission }) {
      this.form.program_products = this.updateProduct(productId, product => {
        this.logging('set commission for product', productId, commission);
        product.commission = commission;
      });
    },
    addMediaKit({ productId, mediaKit }) {
      this.form.program_products = this.updateProduct(productId, product => {
        this.logging('added media kit for product', productId, mediaKit);
        product.media_kits = [mediaKit];
      });
    },
    manageOffer({ productId, offer, status }) {
      this.form.program_products = this.updateProduct(productId, product => {
        let offers = product.product_offers || [];
        if (status) {
          offers.push(offer);
        } else {
          offers = offers.filter(o => !isOfferEq(offer, o));
        }
        product.commission = product.commission || null;
        product.product_offers = offers;
        product.media_kits = product.media_kits || [];
        this.logging(`${status ? 'added' : 'removed'} offer of product`, productId, offer);
      });
    },
    async getProducts() {
      try {
        this.isLoading = true;

        let allProducts = [];
        let currentPage = 1;
        let hasMoreProducts = true;

        while (hasMoreProducts) {
          const { school_products, total_count, total_pages } = await programsService.getUserProducts([
            { key: 'page', value: currentPage },
          ]);

          const filteredProducts = school_products.reduce((acc, { id, library_resource, ...product }) => {
            if (library_resource) {
              acc.push({
                ...product,
                sparkecommerce_product_id: id,
              });
            }
            return acc;
          }, []);

          allProducts = [...allProducts, ...filteredProducts];
          this.numberOfProducts = total_count;

          hasMoreProducts = currentPage < total_pages;
          currentPage++;
        }

        this.products = allProducts;
      } catch (error) {
        toastHelper.dangerMessage(this.$t('sparkmembers.products-list.toast-messages.products-loading-error'));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
