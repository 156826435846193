export enum NewProgramSteps {
  DEFINITIONS = 'definitions',
  PRODUCTS = 'products',
  COMMISSION = 'commission',
  MEDIA_KIT = 'media-kits',
  EXPIRATION_DATE = 'expiration-date',
  SUMMARY = 'summary',
}

export const steps = [
  NewProgramSteps.DEFINITIONS,
  NewProgramSteps.PRODUCTS,
  NewProgramSteps.COMMISSION,
  NewProgramSteps.MEDIA_KIT,
  NewProgramSteps.EXPIRATION_DATE,
  NewProgramSteps.SUMMARY,
];

export const newProgramStepsIndex = a => steps.indexOf(a);

export const isNewProgramStepsLast = a => steps.indexOf(a) === steps.length - 1;

export const newProgramStepsOrdered = (a, b) => steps.indexOf(a) < steps.indexOf(b);

export const isProgramStepNext = (a, b) => steps.indexOf(a) + 1 === steps.indexOf(b);
