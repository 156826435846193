<template>
  <program-editor-form-layout
    :page-number="1"
    :title="$t('sparkaffiliates.programs-list.new-program.program-creator-form.definition-form.title')"
    :subtitle="$t('sparkaffiliates.programs-list.new-program.program-creator-form.definition-form.subtitle')"
  >
    <div class="tlw-flex tlw-flex-col">
      <div class="tlw-w-full tlw-bg-white tlw-p-8">
        <div class="tlw-mb-6 tlw-font-semibold tlw-text-xl">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.definition-form.form-input.title') }}
        </div>
        <hs-group class="tlw-max-w-lg">
          <label>{{
            $t('sparkaffiliates.programs-list.new-program.program-creator-form.definition-form.form-input.name-label')
          }}</label>
          <hs-input
            id="program-name"
            type="text"
            @blur="validation.form.name.$touch()"
            v-model="form.name"
            :state="!validation.form.name.$error ? null : false"
          >
            <template slot="feedback" v-if="validation.form.name.$error">
              <hs-form-invalid-feedback :state="false">
                <span v-if="!validation.form.name.required">
                  O nome do programa é obrigatório
                </span>
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </hs-group>

        <hs-group>
          <label>{{
            $t(
              'sparkaffiliates.programs-list.new-program.program-creator-form.definition-form.form-input.description-label'
            )
          }}</label>
          <hs-text-editor
            v-model="form.description"
            :initialContent="form.description"
            @onUpdate="event => updateDescriptionInput(event.getHTML())"
          />
        </hs-group>

        <hs-group>
          <label>{{
            $t('sparkaffiliates.programs-list.new-program.program-creator-form.definition-form.form-input.rules-label')
          }}</label>
          <hs-text-editor
            :initialContent="form.rules || ''"
            v-model="form.rules"
            @onUpdate="event => updateRulesInput(event.getHTML())"
          />
        </hs-group>
      </div>
    </div>
  </program-editor-form-layout>
</template>

<script>
import ProgramEditorFormLayout from '@/sparkaffiliates/layouts/ProgramEditorFormLayout.vue';

export default {
  components: {
    ProgramEditorFormLayout,
  },
  props: {
    isEditModeActive: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateDescriptionInput(input) {
      this.form.description = input === '<p></p>' ? null : input;
    },
    updateRulesInput(input) {
      this.form.rules = input === '<p></p>' ? null : input;
    },
  },
};
</script>

<style>
.multiselect__tag {
  color: black !important;
}
</style>
