import store from '@/store';
import Features from '@/types/features';

async function tryAddFeatureToSchool(featureId, featureKey) {
  await store.dispatch('school/enableFeature', {
    featureId,
    featureKey,
  });
}

export default {
  async tryAddProducerFeatureToSchool() {
    await tryAddFeatureToSchool(parseInt(process.env.VUE_APP_SPARKAFFILIATES_FEATURE_ID), Features.SPARK_AFFILIATES);
  },
  tryAddAffiliateFeatureToSchool() {
    return tryAddFeatureToSchool(parseInt(process.env.VUE_APP_AFFILIATE_FEATURE_ID), Features.AFFILIATE);
  },
};
