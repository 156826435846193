<template>
  <div>
    <div class="tlw-flex program-creator-layout tlw-p-8 tlw-mb-6">
      <div
        class="program-creator-form__number-circle--active tlw-font-semibold tlw-rounded-full tlw-flex tlw-flex-none tlw-justify-center tlw-h-8 tlw-w-8 tlw-items-center"
      >
        {{ pageNumber }}
      </div>
      <div class="tlw-ml-3 md:tlw-ml-">
        <div class="tlw-font-semibold tlw-text-xl">
          {{ $t(`sparkaffiliates.programs-list.new-program.program-creator-form.steps.${pageNumber}.title`) }}
        </div>
        <div class="tlw-mt-4">
          {{ $t(`sparkaffiliates.programs-list.new-program.program-creator-form.steps.${pageNumber}.text`) }}
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ProgramEditorFormLayout',
  props: {
    pageNumber: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.program-creator-layout {
  background-color: $white;
}

.program-creator-form__step--deactive {
  cursor: default;
}

.program-creator-form__step--input-valid {
  color: var(--color-gray-800);
}

.program-creator-form__number-circle {
  background-color: var(--color-gray-300);
  color: var(--color-gray-800);
}

.program-creator-form__number-circle--active {
  background-color: $cyan;
}

.program-creator-form__number-circle--input-valid {
  background-color: $green-dark;
  color: $white;
}
</style>
