export const makeCheckoutUrl = function(hasSparkCheckout, paymentOption, product, domain) {
  if (hasSparkCheckout) {
    return paymentOption.url;
  }

  if (paymentOption.kind === 'free') {
    return `https://${domain}/one_click_buy_free/${product.slug}?payment_option_id=${paymentOption.id}`;
  }

  return `https://${domain}/express_checkout/payment?payment_option_id=${paymentOption.id}&school_product_id=${
    product.id
  }`;
};
