<template>
  <div class="collapse-content">
    <div class="tlw-bg-white tlw-shadow-grey tlw-px-8 tlw-py-6 tlw-rounded-md">
      <button
        block
        class="btn-collapse tlw-pb-0 tlw-text-left tlw-items-center tlw-rounded-t-md tlw-w-full"
        @click="visible = !visible"
      >
        <hs-icon class="icon text-dark" variant="light" :icon="visible ? 'chevron-up' : 'chevron-down'" />

        <div class="tlw-flex tlw-w-full tlw-justify-between">
          <div class="tlw-text-black tlw-text-base title tlw-font-semibold tlw-ml-4">
            {{ visible ? '' : title }}
          </div>

          <div v-if="!visible" class="tlw-flex tlw-gap-6 tlw-items-center">
            <hs-badge v-if="product.commission" variant="purple" pill> Comissão {{ product.commission }}% </hs-badge>
            <hs-badge
              v-if="product.media_kits[0]"
              variant="success"
              pill
              v-b-tooltip.hover
              title="Produto com mídia kit vinculado"
              >Com mídia kit</hs-badge
            >
            <hs-badge v-else variant="danger" pill v-b-tooltip.hover title="Produto sem mídia kit vinculado"
              >Sem mídia kit</hs-badge
            >
          </div>
        </div>
      </button>
      <b-collapse v-model="visible" accordion="media-kit-accordion">
        <div class="b-collapse tlw-relative tlw-pl-8 tlw-pt-4">
          <slot></slot>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: this.collapsed,
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(value) {
        this.$emit('opened', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  transition: 0.5s transform ease-in-out;
}

.collapsed .icon {
  transform: rotate(180deg);
}

.btn-collapse {
  display: flex;

  &:active {
    background-color: $white;
  }
  &:active:focus {
    box-shadow: none !important;
  }
}
</style>
