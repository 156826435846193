<template>
  <hsModal :id="sidebarId" size="md" :hide-header="true">
    <div class="program-deletion-sidebar-modal__container tlw-flex tlw-justify-center tlw-pt-8 tlw-pb-2">
      <div
        class="program-deletion-sidebar-modal__content tlw-grid tlw-self-center tlw-justify-items-center tlw-text-center"
      >
        <img class="tlw-pb-10" :src="images.addProgramIcon" />
        <div class="program-deletion-sidebar-modal__title tlw-font-bold tlw-text-2xl tlw-pb-4">
          {{ $t('sparkaffiliates.programs-list.my-programs.program-deletion-sidebar-modal.title') }}
        </div>
        <div class="program-deletion-sidebar-modal__subtitle tlw-pb-6">
          {{ $t('sparkaffiliates.programs-list.my-programs.program-deletion-sidebar-modal.subtitle') }}
        </div>
        <hs-button class="tlw-mb-4 tlw-w-4/5" variant="danger" @click="continueToDeletion">
          {{ $t('sparkaffiliates.programs-list.my-programs.program-deletion-sidebar-modal.continue-button') }}
        </hs-button>
        <hs-button class="tlw-w-4/5" variant="outline-danger" @click="cancelOperation">
          {{ $t('sparkaffiliates.programs-list.my-programs.program-deletion-sidebar-modal.secondary-button') }}
        </hs-button>
      </div>
    </div>
  </hsModal>
</template>
<script>
import { hsModal } from '@/components';

export default {
  name: 'DeletionConfirmationSidebarModal',
  components: {
    hsModal,
  },
  props: {
    sidebarId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      images: {
        addProgramIcon: require('@/assets/images/SparkAffiliates/Programs/warning-deletion-robot.svg'),
      },
    };
  },
  methods: {
    continueToDeletion() {
      this.$emit('confirm');
    },
    cancelOperation() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.program-deletion-sidebar-modal__content {
  width: 408px;
}
</style>
