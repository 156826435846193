import copy from 'copy-to-clipboard';

export default {
  methods: {
    clipboardCopy(_ref, value = '') {
      try {
        return copy(value);
      } catch (e) {
        return false;
      }
    },
  },
};
