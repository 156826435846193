<template>
  <program-editor-form-layout
    :page-number="5"
    :title="$t('sparkaffiliates.programs-list.new-program.program-creator-form.expiration-date-form.title')"
    :subtitle="$t('sparkaffiliates.programs-list.new-program.program-creator-form.expiration-date-form.subtitle')"
  >
    <div class="tlw-flex tlw-flex-col">
      <div class="tlw-w-full tlw-bg-white tlw-p-8">
        <div class="tlw-mb-6 tlw-font-semibold tlw-text-xl">
          {{
            $t('sparkaffiliates.programs-list.new-program.program-creator-form.expiration-date-form.form-input.title')
          }}
        </div>
        <div class="tlw-text-sm tlw-font-semibold tlw-mb-4">
          {{
            $t(
              'sparkaffiliates.programs-list.new-program.program-creator-form.expiration-date-form.form-input.expiration-type-label'
            )
          }}
        </div>
        <div class="tlw-grid tlw-grid-cols-3">
          <div class="tlw-col-span-1">
            <hs-radio class="tlw-mb-2" :checked="form.expiration_date" :value="null" @input="updateDate">
              <div class="tlw-text-sm">Programa <strong>sem</strong> data de expiração</div>
            </hs-radio>
            <hs-radio
              class="tlw-mb-4"
              :checked="form.expiration_date"
              :value="form.expiration_date || new Date()"
              @input="updateDate"
            >
              <div class="tlw-text-sm">Programa <strong>com</strong> data de expiração</div>
            </hs-radio>
          </div>
          <div v-if="Boolean(form.expiration_date)" class="tlw-col-span-2 tlw-w-1/2">
            <div class="tlw-text-sm tlw-font-bold">
              {{
                $t(
                  'sparkaffiliates.programs-list.new-program.program-creator-form.expiration-date-form.form-input.expiration-date'
                )
              }}
            </div>
            <hs-date
              v-model="form.expiration_date"
              size="sm"
              :min="new Date()"
              value-as-date
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </div>
        </div>
      </div>
    </div>
  </program-editor-form-layout>
</template>

<script>
import ProgramEditorFormLayout from '@/sparkaffiliates/layouts/ProgramEditorFormLayout.vue';

export default {
  components: {
    ProgramEditorFormLayout,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    formValidations: {
      type: Object,
    },
  },
  methods: {
    updateDate($event) {
      this.form.expiration_date = $event;
    },
  },
};
</script>
