import debug from 'debug';

const logging = debug('hs:block-on-leave');

export const NO_BLOCKING = null;
export const LEAVE = 'leave';

// [piada] Como a gambiarra funciona...
// O componente que carregar esse mixin
// deve estar diretamente na rota do vue-router
// para que ele possa usar `beforeRouteLeave`...
// O componente deve prover um método chamado
// `mustBlockLeaving` que deve retornar uma string ou null
// (use a constante `NO_BLOCKING` acima).
export default {
  data() {
    return {
      blockedBy: NO_BLOCKING,
      saveRouteTo: null,
    };
  },
  beforeRouteLeave(to, _from, next) {
    if (this.blockedBy === LEAVE || (this.blockedBy = this.mustBlockLeaving()) === NO_BLOCKING) {
      logging('leaving to', to.name);
      this.removeBlock();
      next();
    } else {
      logging('blocked by', this.blockedBy, to.name);
      this.saveRouteTo = to;
      next(false);
    }
  },
  methods: {
    async confirmLeaving() {
      try {
        logging('confirmg leaving', this.saveRouteTo.name);
        this.blockedBy = LEAVE;

        await this.$nextTick();

        this.$router.push({ name: this.saveRouteTo.name });
      } catch (e) {
        logging('exception raised when confirm leaving', e);
      }
    },
    removeBlock() {
      logging('remove block');
      this.saveRouteTo = null;
      this.blockedBy = NO_BLOCKING;
    },
    blockBy(reason) {
      this.blockedBy = reason;
    },
    saveRoute(route) {
      this.saveRouteTo = route;
    },
  },
};
