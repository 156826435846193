<template>
  <page-layout>
    <template v-slot:header>
      <PageHeader
        :title="$t('sparkaffiliates.programs-list.header.title')"
        :sub-title="$t('sparkaffiliates.programs-list.header.subtitle')"
        :back-text="$t('sparkaffiliates.programs-list.header.backtext')"
        back-route="ProgramPage"
      />
    </template>
    <div class="md:tlw-py-8 md:tlw-px-10 tlw-py-4 tlw-px-6">
      <hsLoading v-if="isLoading" />
      <ProgramEditorForm
        v-else
        :step="currentStep"
        :steps="steps"
        :form="form"
        :products="products"
        :offers="offers"
        :validation="$v"
        :validateSteps="validateSteps()"
        @goToStep="setSelectedStep"
        @add-media-kit="addMediaKit"
        @selected-offer="manageOffer"
        @loaded-offers="loadedProductOffers"
        @set-product-commission="setProductCommission"
      >
        <template slot="controls">
          <MButton
            v-if="currentStep != 'definitions'"
            variant="primary-outline"
            class="tlw-w-68 tlw-mt-6 tlw-flex tlw-self-end tlw-mr-4"
            @click="goToPreviousStep"
            :label="$t('sparkaffiliates.programs-list.new-program.program-editor-form.wizard-steps.previous-step')"
          />

          <MButton
            :variant="!isFormValid ? 'light' : 'primary'"
            class="tlw-w-72 tlw-mt-6 tlw-flex tlw-self-end"
            :disabled="!isFormValid"
            @click="editProgram"
            :label="nextStepAccordingToValidation()"
          />
        </template>
      </ProgramEditorForm>
    </div>
  </page-layout>
</template>

<script>
import debug from 'debug';
import PageLayout from '@/layout/PageLayout.vue';
import { hsLoading } from '@/components';
import PageHeader from '@/components/_structures/PageHeader';
import toastHelper from '@/shared/helpers/toast';
import { makeCheckoutUrl } from '@/libs/ecommerce';
import { NewProgramSteps } from '@/libs/new_affiliate_program';
import programsService from '@/sparkaffiliates/services/programs.js';
import ProgramEditorForm from '@/sparkaffiliates/views/Producer/ProgramEditorForm';
import ProgramEditingMixin from '@/sparkaffiliates/mixins/ProgramEditingMixin.js';
import MButton from '@/shared/components/MButton.vue';

const logging = debug('hs:edit-program');

export default {
  name: 'ProgramEditor',
  mixins: [ProgramEditingMixin],
  components: {
    PageLayout,
    hsLoading,
    PageHeader,
    ProgramEditorForm,
    MButton,
  },
  data() {
    return {
      logging,
      isLoading: false,
      program: null,
    };
  },
  mounted() {
    this.getProducts().then(() => this.fetchData(this.programId));
  },
  computed: {
    isFormValid() {
      const self = this;
      const { currentStep } = self;
      const validation = self.validateSteps();
      return _.reduce(
        validation,
        (acc, x, key) => {
          if (currentStep == NewProgramSteps.PRODUCTS) {
            return (
              (!validation[NewProgramSteps.COMMISSION] && !validation[NewProgramSteps.MEDIA_KIT]) ||
              self.validateByListKeys([NewProgramSteps.PRODUCTS, NewProgramSteps.COMMISSION, NewProgramSteps.MEDIA_KIT])
            );
          }
          if (currentStep == NewProgramSteps.COMMISSION) {
            return !validation[NewProgramSteps.MEDIA_KIT];
          }
          return acc && Boolean(x);
        },
        true
      );
    },
    hasSparkCheckout() {
      return this.$FCL.hasSparkCheckout();
    },
    programId() {
      return this.$route.params.id;
    },
    tabFromRoute() {
      return this.$route.query.step || NewProgramSteps.DEFINITIONS;
    },
  },
  methods: {
    validateByListKeys(ls) {
      const validation = this.validateSteps();
      return _.reduce(ls, (acc, k) => acc && validation[k], true);
    },
    nextStepAccordingToValidation() {
      const validation = this.validateSteps();
      if (this.currentStep == NewProgramSteps.PRODUCTS) {
        if (!validation[NewProgramSteps.COMMISSION]) {
          return 'Editar comissão';
        }
      }
      if (this.currentStep == NewProgramSteps.COMMISSION) {
        if (!validation[NewProgramSteps.COMMISSION]) {
          return 'Editar media kits';
        }
      }
      return this.$t('sparkaffiliates.programs-list.new-program.program-editor-form.wizard-steps.next-step');
    },

    validateSteps() {
      return {
        [NewProgramSteps.DEFINITIONS]: this.validateDefinitions,
        [NewProgramSteps.PRODUCTS]: this.validateProducts,
        [NewProgramSteps.COMMISSION]: this.validateCommissions,
        [NewProgramSteps.MEDIA_KIT]: this.validateMediaKit,
        [NewProgramSteps.EXPIRATION_DATE]: this.validateExpirationDate,
        [NewProgramSteps.SUMMARY]: this.validateSummary,
      };
    },
    goToPreviousStep() {
      const next = {
        [NewProgramSteps.DEFINITIONS]: NewProgramSteps.DEFINITIONS,
        [NewProgramSteps.PRODUCTS]: NewProgramSteps.DEFINITIONS,
        [NewProgramSteps.COMMISSION]: NewProgramSteps.PRODUCTS,
        [NewProgramSteps.MEDIA_KIT]: NewProgramSteps.COMMISSION,
        [NewProgramSteps.EXPIRATION_DATE]: NewProgramSteps.MEDIA_KIT,
        [NewProgramSteps.SUMMARY]: NewProgramSteps.EXPIRATION_DATE,
      }[this.currentStep];
      this.setSelectedStep(next);
    },
    setSelectedStep(step) {
      this.currentStep = step;
    },
    urlLink(paymentOption, product) {
      return makeCheckoutUrl(this.hasSparkCheckout, paymentOption, product, this.getDefaultDomain);
    },
    async fetchData(programId) {
      try {
        this.isLoading = true;

        const { data: program } = await programsService.getProgram(programId);

        this.program = program;

        this.form = _.cloneDeep(program);

        logging('loaded program', program);
      } catch (e) {
        logging('failed to load the program', this.programId, e);
        toastHelper.dangerMessage(this.$t('sparkaffiliates.programs-list.toast.program-editor.danger'));
      } finally {
        this.isLoading = false;
      }
    },
    async editProgram() {
      if (
        this.currentStep == NewProgramSteps.PRODUCTS &&
        !this.validateByListKeys([NewProgramSteps.COMMISSION, NewProgramSteps.MEDIA_KIT])
      ) {
        return (this.currentStep = NewProgramSteps.COMMISSION);
      }
      if (this.currentStep == NewProgramSteps.COMMISSION && !this.validateByListKeys([NewProgramSteps.MEDIA_KIT])) {
        return (this.currentStep = NewProgramSteps.MEDIA_KIT);
      }

      const { program_products, ...program } = this.form;

      try {
        const products = Object.entries(program_products);

        const { data: updatedProgram } = await programsService.editProgram(program, products, this.urlLink);
        toastHelper.successMessage(this.$t('sparkaffiliates.programs-list.toast.program-editor.success'));
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    },
  },
};
</script>
