<template>
  <hsLoading v-if="isLoading" />
  <div v-else data-testid="invite-affiliate-page-content">
    <div class="tlw-font-bold tlw-text-xl tlw-pb-6">
      {{ $t('sparkaffiliates.producer-panel.add-affiliation.title') }}
    </div>
    <div class="invite-affiliate__form">
      <hs-group>
        <label>
          {{ $t('sparkaffiliates.producer-panel.add-affiliation.name.label') }}
        </label>
        <hs-input
          id="affiliate-name"
          :placeholder="$t('sparkaffiliates.producer-panel.add-affiliation.name.label')"
          type="text"
          @blur="$v.form.affiliateName.$touch()"
          v-model="form.affiliateName"
          :state="!$v.form.affiliateName.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.form.affiliateName.$error">
            <hs-form-invalid-feedback :state="false">
              <span v-if="!$v.form.affiliateName.required">
                {{ $t('sparkaffiliates.producer-panel.add-affiliation.name.validations.required') }}
              </span>
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </hs-group>

      <hs-group>
        <label>
          {{ $t('sparkaffiliates.producer-panel.add-affiliation.email.label') }}
        </label>
        <hs-input
          id="affiliate-email"
          :placeholder="$t('sparkaffiliates.producer-panel.add-affiliation.email.placeholder')"
          type="text"
          @blur="$v.form.affiliateEmail.$touch()"
          v-model="form.affiliateEmail"
          :state="!$v.form.affiliateEmail.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.form.affiliateEmail.$error">
            <hs-form-invalid-feedback :state="false">
              <span v-if="!$v.form.affiliateEmail.required">
                {{ $t('sparkaffiliates.producer-panel.add-affiliation.email.validations.required') }}
              </span>
              <span v-if="!$v.form.affiliateEmail.email">
                {{ $t('sparkaffiliates.producer-panel.add-affiliation.email.validations.error') }}
              </span>
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </hs-group>

      <hs-group>
        <label for="select-program">{{ $t('sparkaffiliates.producer-panel.add-affiliation.programs.label') }}</label>
        <hs-multiselect
          id="select-program"
          v-model="form.program"
          label="title"
          track-by="id"
          :placeholder="$t('sparkaffiliates.producer-panel.add-affiliation.programs.placeholder')"
          :options="programOptions"
          :allow-empty="false"
          :show-labels="false"
          :loading="isLoading"
        >
        </hs-multiselect>
      </hs-group>

      <MButton
        class="invite-affiliate__send-button"
        :variant="$v.form.$invalid ? 'disabled' : 'primary'"
        @click="openConfirmationModal"
        :disabled="$v.form.$invalid"
      >
        {{ $t('sparkaffiliates.producer-panel.add-affiliation.send-button') }}
      </MButton>
    </div>

    <ConfirmationModal
      modal-id="send-email-confirmation"
      :title="$t('sparkaffiliates.affiliation-invitation.confirmation-modal.title')"
      :subtitle="
        $t('sparkaffiliates.affiliation-invitation.confirmation-modal.subtitle', {
          programName: form.program.title,
          name: form.affiliateName,
          email: form.affiliateEmail,
        })
      "
      :continue-button-text="$t('sparkaffiliates.affiliation-invitation.confirmation-modal.continue-button')"
      :cancel-button-text="$t('sparkaffiliates.affiliation-invitation.confirmation-modal.secondary-button')"
      :alert-icon="require('@/assets/images/SparkAffiliates/Programs/add-suggestion.svg')"
      @confirmOperation="sendEmail"
    />
    <b-modal
      v-model="isOpenInviteLinkModal"
      id="affiliation-invite-link-modal"
      size="md"
      centered
      scrollable
      visible
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <ParticipationInviteInformation
        :invite="invite"
        kind="affiliation"
        close-button-label="Convites pendentes"
        invite-more-label="Novo convite de afiliação"
        @go-to-pending-invites="goToPendingInvites"
        @go-to-invite-more="inviteMore"
      />
    </b-modal>
  </div>
</template>

<script>
import debug from 'debug';
import { required, email } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import toastHelper from '@/shared/helpers/toast';
import programsService from '@/sparkaffiliates/services/programs.js';
import affiliationInviteService from '@/sparkaffiliates/services/affiliation_invite.js';
import { hsLoading } from '@/components';
import ConfirmationModal from '@/sparkaffiliates/views/Producer/ProgramPage/components/shared/ConfirmationModal';
import { INVITE_AFFILIATE_TAB } from '@/libs/affiliates';
import tracking from '@/shared/helpers/tracking';
import MButton from '@/shared/components/MButton.vue';
import ParticipationInviteInformation from '@/components/ParticipationInviteInformation';

const logging = debug('hs:invite_affiliates');
const SEND_EMAIL_CONFIRMATION = 'send-email-confirmation';

export default {
  name: INVITE_AFFILIATE_TAB,
  components: {
    hsLoading,
    ConfirmationModal,
    MButton,
    ParticipationInviteInformation,
  },
  data() {
    return {
      form: {
        affiliateName: '',
        affiliateEmail: '',
        program: {},
      },
      invite: null,
      isOpenInviteLinkModal: false,
      isLoading: false,
      programOptions: [],
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    mailerParams() {
      return {
        program_id: this.form.program.id,
        name: this.form.affiliateName,
        email: this.form.affiliateEmail,
      };
    },
    openInviteLinkModal() {
      return Boolean(this.invite);
    },
  },
  validations: {
    form: {
      affiliateName: {
        required,
      },
      affiliateEmail: {
        email,
        required,
      },
      program: {
        required,
      },
    },
  },
  async mounted() {
    this.fetchProgramsToSelect();
  },
  methods: {
    async fetchProgramsToSelect() {
      try {
        this.isLoading = true;

        const { programs } = await programsService.getOrganizationPrograms({
          items: 9999,
        });
        this.programOptions = programs.map(program => ({
          id: program.id,
          title: program.name,
        }));
      } catch (e) {
        logging('failed to load programs', e);
        toastHelper.dangerMessage('Ocorreu algum problema ao carregar os seus programas');
      } finally {
        this.isLoading = false;
      }
    },
    closeInviteLinkModal() {
      this.isOpenInviteLinkModal = false;
    },
    goToPendingInvites() {
      this.closeInviteLinkModal();
      this.$emit('change-tab', 'AffiliationInvitesTab');
    },
    inviteMore() {
      this.closeInviteLinkModal();
      this.resetForm();
    },
    async sendEmail() {
      this.$bvModal.hide(SEND_EMAIL_CONFIRMATION);

      try {
        this.isLoading = true;

        const { affiliateName, affiliateEmail, program_id } = this.mailerParams;
        const { data } = await affiliationInviteService.invite(this.mailerParams);
        tracking.affiliateInviteSent(this.selectedSchool.id, program_id, affiliateName, affiliateEmail);

        this.invite = data;
        this.isOpenInviteLinkModal = Boolean(this.invite);

        toastHelper.successMessage('Convite enviado com sucesso!');
      } catch (e) {
        logging('failed to invite an affiliate', e);
        toastHelper.dangerMessage('Ocorreu um problema ao enviar seu e-mail');
      } finally {
        this.isLoading = false;
      }
    },
    openConfirmationModal() {
      this.$bvModal.show(SEND_EMAIL_CONFIRMATION);
    },
    resetForm() {
      const { form } = this.$options.data.call(this);
      this.form = form;
      this.$v.$reset();
    },
  },
  watch: {
    'form.affiliateEmail': {
      handler(newEmail) {
        this.form.affiliateEmail = newEmail.toLowerCase();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.invite-affiliate__form {
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
}

.invite-affiliate__send-button {
  width: fit-content;
  height: fit-content;
  margin-top: 32px;
}
</style>
