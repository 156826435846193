<template>
  <fragment>
    <div class="tlw-px-8 tlw-pt-8 tlw-flex tlw-flex-col">
      <slot name="top" />
      <div class="tlw-flex tlw-flex-row tlw-items-center tlw-mb-6">
        <img :src="images.detailsIcon" alt="Detalhes" />
        <div class="tlw-font-semibold tlw-text-xl tlw-pl-2 tlw-mr-4 tlw-w-4/5">{{ program.name }}</div>
        <div v-if="canEdit" @click="goToProgramDetails" class="sidebar-program-info__icon tlw-cursor-pointer tlw-mr-2">
          <hs-icon icon="pencil-alt" />
        </div>
        <div
          id="delete"
          @click="deleteProgram"
          class="sidebar-program-info__icon sidebar-program-info__icon--danger tlw-cursor-pointer"
        >
          <hs-icon icon="trash-alt" />
        </div>
      </div>
      <div class="sidebar-program-info__details-item tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <hs-icon icon="calendar-alt" />
        <div class="tlw-text-sm tlw-col-span-7">
          <p class="tlw-m-0">{{ createdAtTitle }}</p>
          <strong>{{ createdAtValue }}</strong>
        </div>
      </div>
      <div class="sidebar-program-info__details-item tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <hs-icon icon="calendar-day" />
        <div class="tlw-text-sm tlw-col-span-7">
          <p class="tlw-m-0">{{ $t('sparkaffiliates.programs-list.sidebar.expiration-date') }}</p>
          <strong>{{ expirationDate }}</strong>
        </div>
      </div>
      <div class="sidebar-program-info__details-item tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <hs-icon class="tlw-self-start" icon="video" />
        <div class="tlw-col-span-7">
          <p class="tlw-m-0 tlw-text-sm">
            {{ $t('sparkaffiliates.programs-list.sidebar.products') }}
          </p>
          <div class="tlw-text-sm tlw-font-bold tlw-mt-1">
            <div v-for="product in activeProductsName" :key="product.id">
              {{ product }}
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-program-info__details-item tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <hs-icon icon="hand-holding-box" />
        <div class="tlw-text-sm tlw-col-span-7">
          <p class="tlw-m-0">Regra de comissionamento</p>
          <hs-badge variant="purple" pill>{{ displayCommissionType(program) }}</hs-badge>
        </div>
      </div>
      <div class="sidebar-program-info__details-item tlw-grid tlw-grid-cols-8 tlw-items-center tlw-mb-2">
        <div />
        <div class="tlw-text-sm tlw-col-span-7">
          <MButton
            id="editButton"
            variant="primary"
            :label="$t('sparkaffiliates.programs-list.sidebar.see-more')"
            @click="goToProgramDetails"
            class="tlw-font-bold tlw-text-sm tlw-mt-2"
          />
        </div>
      </div>
    </div>
    <hr class="divider tlw-my-6" />
    <div class="tlw-px-8 tlw-flex tlw-flex-row tlw-gap-4">
      <div class="tlw-font-semibold tlw-text-gray-500">
        {{ activationTitle }}
      </div>
      <div class="program-item__card-enable tlw-flex tlw-gap-x-3 tlw-flex-row">
        {{ program.enabled ? 'Desativar' : 'Ativar' }}
        <MSwitch @change="updateProgramStatus" :id="`toggle-sidebar-${program.id}`" v-model="program.enabled" />
      </div>
    </div>
    <hr class="divider tlw-my-6" />
  </fragment>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { displayCommissionType } from '@/libs/program';
import MButton from '@/shared/components/MButton.vue';
import MSwitch from '@/shared/components/MSwitch.vue';

export default {
  name: 'ProgramDetails',
  components: {
    MButton,
    MSwitch,
  },
  data() {
    return {
      images: {
        detailsIcon: require('@/assets/images/SparkAffiliates/Programs/details.svg'),
      },
      activeProductsName: [],
    };
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    createdAtTitle: {
      type: String,
      required: true,
    },
    createdAtValue: {
      type: String,
      required: true,
    },
    activationTitle: {
      type: String,
      required: true,
    },
    getActiveProductsName: {
      type: Function,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
    sidebarId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.getProductsName();
  },
  computed: {
    expirationDate() {
      const expirationDate = _.get(this.program, 'expiration_date', null);
      return expirationDate ? dayjs(this.program.expiration_date).format('DD/MM/YYYY') : 'Sem expiração';
    },
    countAffiliations() {
      return _.get(this.program, 'affiliations', []).length;
    },
  },
  methods: {
    displayCommissionType(program) {
      return displayCommissionType(_.get(program, 'commission_type', ''));
    },
    goToProgramEdit() {
      this.$emit('goToProgramEdit', { step: null, program: this.program });
    },
    goToProgramDetails() {
      this.$emit('goToProgramDetails', this.program);
    },
    deleteProgram() {
      this.$emit('delete', this.program);
    },
    async updateProgramStatus(status) {
      this.$emit('toggle-activation', {
        status,
        program: this.program,
      });
    },
    async getProductsName() {
      this.activeProductsName = await this.getActiveProductsName(this.program);
    },
  },
  watch: {
    program() {
      this.getProductsName();
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-program-info__icon {
  color: $cyan;
  height: fit-content;
}
.sidebar-program-info__icon--danger {
  color: $cherry;
}

.sidebar-program-info__details-item {
  align-items: baseline;
}

.divider {
  border: 1px solid $grey-22;
}
</style>
