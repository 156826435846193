<template>
  <hsLoading v-if="isLoading" />
  <div v-else>
    <EmptyList
      v-if="!hasFilters && !hasAffiliationInvites"
      v-on="$listeners"
      :title="$t('sparkaffiliates.affiliation-invites-list.my-invites.empty-list.title')"
      :subtitle="$t('sparkaffiliates.affiliation-invites-list.my-invites.empty-list.subtitle')"
      :button-text="$t('sparkaffiliates.affiliation-invites-list.my-invites.empty-list.button-text')"
    />
    <div v-else>
      <program-filter
        :nameSearchTerm="nameSearchTerm"
        :sortOptions="sortOptions"
        :sortByValue="sortByValue"
        :programOptions="programOptions"
        :filterByProgram="filterByProgram"
        @input-search-term="filterByText"
        @selected-ordering="filterBySelectedOption"
        @selected-program="filterBySelectedProgram"
        @clear-filter="clearFilter"
        class="pb-5"
      />

      <affiliation-invites-list :affiliation-invites="affiliationInvites" />

      <hs-pagination
        v-if="pagination.totalCount > pagination.perPage"
        v-model="pagination.currentPage"
        :go-page-text="$t('sparkmembers.coupons.views.list.index.pagination.go-to-page')"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalCount"
        align="center"
      />
    </div>
  </div>
</template>

<script>
import debug from 'debug';
import { hsLoading } from '@/components';
import LoadingMixin from '@/shared/mixins/LoadingMixin';
import PaginationMixin from '@/sparkaffiliates/mixins/PaginationMixin';
import ProgramFilterMixin from '@/sparkaffiliates/mixins/ProgramFilterMixin';
import ToastHelper from '@/shared/helpers/toast';
import affiliationInviteService from '@/sparkaffiliates/services/affiliation_invite.js';
import EmptyList from '@/sparkaffiliates/views/Producer/AffiliatesPage/components/shared/EmptyList.vue';
import AffiliationInvitesList from '@/sparkaffiliates/views/AffiliationInvitesList.vue';
import { MY_AFFILIATION_INVITES_TAB } from '@/libs/affiliates';
import ProgramFilter from '@/sparkaffiliates/views/Producer/components/ProgramFilter.vue';
import programsService from '@/sparkaffiliates/services/programs';

const logging = debug('hs:affiliation-invites');

export default {
  name: MY_AFFILIATION_INVITES_TAB,
  mixins: [LoadingMixin, ProgramFilterMixin, PaginationMixin],
  components: {
    hsLoading,
    ProgramFilter,
    AffiliationInvitesList,
    EmptyList,
  },
  data() {
    return {
      isLoading: false,
      affiliationInvites: [],
      programs: [],
      defaultSearchParams: {
        status: 'pending',
      },
    };
  },
  watch: {
    'pagination.currentPage': function(newPage, _oldPage) {
      this.updatePage(newPage);
    },
  },
  created() {
    this.initiateProgramFilter(this.updateAffiliationInvitesFromFilter);
    this.initiatePagination(this.updateAffiliationInvitesFromPagination);
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    countAffiliationInvites() {
      return this.pagination.totalCount;
    },
    hasAffiliationInvites() {
      return this.pagination.totalCount > 0;
    },
  },
  methods: {
    clearFilter() {
      this.clearProgramFilter();
      this.updateAffiliationInvitesFromFilter();
    },
    onFailedLoading(e) {
      logging('failed to load the invites', e);
      ToastHelper.dangerMessage(this.$t('Ocorreu algum problema ao listar os seus convites de afiliação'));
    },
    updateAffiliationInvitesFromFilter() {
      this.loadAffiliationInvites({
        ...this.searchParams,
        ...this.defaultSearchParams,
      }).catch(this.onFailedLoading);
    },
    updateAffiliationInvitesFromPagination() {
      this.loadAffiliationInvites({
        ...this.searchParams,
        ...this.defaultSearchParams,
        page: this.pagination.currentPage,
      }).catch(this.onFailedLoading);
    },
    loadAffiliationInvites(params = {}) {
      return this.withLoading(async () => {
        const { affiliationInvites, ...pagination } = await affiliationInviteService.getOrganizationAffiliationInvites(
          params
        );

        this.affiliationInvites = affiliationInvites;
        this.pagination = pagination;

        logging('loaded %d affiliation invites', affiliationInvites.length, affiliationInvites);
      });
    },
    updateProgramsFilter() {
      this.programOptions = this.programs.map(program => ({
        id: program.id,
        title: program.name,
      }));
    },
    async loadPrograms(params = {}) {
      const { programs } = await programsService.getOrganizationPrograms(params);
      this.programs = programs;
      logging('loaded %d programs', programs.length, programs);
      this.updateProgramsFilter();
    },
    async fetchData() {
      this.loadAffiliationInvites(this.defaultSearchParams)
        .then(() => this.loadPrograms({ items: 50 }))
        .catch(this.onFailedLoading);
    },
  },
};
</script>
