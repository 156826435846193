<template>
  <program-editor-form-layout
    :page-number="6"
    :title="$t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.title')"
    :subtitle="$t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.subtitle')"
  >
    <div class="tlw-flex tlw-flex-col tlw-gap-6">
      <SummaryCollapse title="Definições" :collapsed="true">
        <div class="tlw-flex tlw-gap-2">
          <div class="tlw-font-semibold tlw-mb-1">
            {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.definition.program-name') }}
          </div>
          <div @click="goToStep('definitions')" class="edit-icon__primary-icon tlw-cursor-pointer">
            <hs-icon icon="pencil-alt" />
          </div>
        </div>
        <div class="program-name tlw-text-sm tlw-mb-4">{{ form.name }}</div>
        <div class="tlw-flex tlw-gap-2">
          <div class="tlw-font-semibold tlw-mb-1">
            {{
              $t(
                'sparkaffiliates.programs-list.new-program.program-creator-form.summary.definition.program-description'
              )
            }}
          </div>
          <div @click="goToStep('definitions')" class="edit-icon__primary-icon tlw-cursor-pointer">
            <hs-icon icon="pencil-alt" />
          </div>
        </div>
        <div v-html="form.description" class="program-description tlw-text-sm tlw-mb-4" />
        <div class="tlw-flex tlw-gap-2">
          <div class="tlw-font-semibold tlw-mb-1">
            {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.definition.program-rules') }}
          </div>
          <div @click="goToStep('definitions')" class="edit-icon__primary-icon tlw-cursor-pointer">
            <hs-icon icon="pencil-alt" />
          </div>
        </div>
        <div v-html="form.rules" class="program-rules tlw-text-sm tlw-mb-4" />
      </SummaryCollapse>
      <SummaryCollapse title="Detalhamento">
        <div class="tlw-grid tlw-grid-cols-2 tlw-gap-6">
          <SummaryDetailsCard
            v-for="(product, key) in form.program_products"
            :key="key"
            :product="product"
            :commission="product.commission"
            v-on="$listeners"
          />
        </div>
      </SummaryCollapse>
      <SummaryCollapse title="Data de expiração">
        <div class="tlw-flex tlw-gap-2">
          <div class="tlw-font-semibold tlw-mb-1">
            Programa {{ form.expiration_date ? 'com' : 'sem' }} data de expiração definida
          </div>
          <div @click="goToStep('expiration-date')" class="edit-icon__primary-icon tlw-cursor-pointer">
            <hs-icon icon="pencil-alt" />
          </div>
        </div>
        <div v-if="form.expiration_date" class="tlw-text-sm">{{ getFormattedDate }}</div>
      </SummaryCollapse>
    </div>
  </program-editor-form-layout>
</template>

<script>
import ProgramEditorFormLayout from '@/sparkaffiliates/layouts/ProgramEditorFormLayout.vue';
import SummaryCollapse from './shared/SummaryCollapse';
import SummaryDetailsCard from './SummaryDetailsCard';

export default {
  components: {
    SummaryCollapse,
    SummaryDetailsCard,
    ProgramEditorFormLayout,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    isEditModeActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getFormattedDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(this.form.expiration_date).toLocaleDateString('pt-BR', options);
    },
  },
  methods: {
    goToStep(step) {
      this.$emit('goToStep', step);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-icon__primary-icon {
  color: $cyan;
  height: fit-content;
}
</style>
