














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AffiliationStatus } from '@/types/affiliates';
import MAvatar from '@/shared/components/MAvatar.vue';
import { AVATAR_BOT_3 } from '@/libs/avatar';
import toastHelper from '@/shared/helpers/toast';
import HsSwitch from '@/components/hsSwitch.vue';
import { ColourByStatus, InviteStatusLabel } from '@/libs/coproduction';
import MSwitch from '@/shared/components/MSwitch.vue';

const fields = translate => {
  const sortable = { sortable: true };
  return [
    makeField('image', ''),
    makeField('name', translate('Nome/E-mail'), sortable),
    makeField('programs', translate('Programas'), sortable),
    makeField('activation', translate('Afiliação'), sortable),
    makeField('action', ''),
  ];
};

const makeField = (key, label, extra = {}) => ({
  key,
  label,
  ...extra,
});

@Component({
  components: {
    MAvatar,
    HsSwitch,
    MSwitch,
  },
})
export default class AffiliatesList extends Vue {
  @Prop({ type: Array }) affiliates!: any[];

  data() {
    return {
      fields: fields(key => this.$t(key)),
    };
  }

  get newAfilliates() {
    return this.affiliates.map(affiliate => ({
      ...affiliate,
      status: this.isEnabled(affiliate.status),
    }));
  }

  defaultAvatar = AVATAR_BOT_3;

  isEnabled(status) {
    return status === AffiliationStatus.ACCEPTED;
  }
  selectedAffiliate(affiliate) {
    this.$emit('selected-affiliate', affiliate);
  }
  toggleActivation(status, affiliation) {
    this.$emit('toggle-activation', {
      status,
      affiliation,
    });
  }
}
