<template>
  <div class="tlw-flex tlw-p-2 tlw-items-center">
    <img :src="images.tipsIcon" class="tlw-pr-4" />
    <a :href="url" class="tlw-font-bold tlw-text-sm">{{ title }}</a>
  </div>
</template>

<script>
export default {
  name: 'TipsItem',
  data() {
    return {
      images: {
        tipsIcon: require('@/assets/images/SparkAffiliates/ProducerPanel/tips-icon.svg'),
      },
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
