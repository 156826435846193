<template>
  <div class="empty-program__container tlw-flex tlw-justify-center">
    <div class="empty-program__content tlw-grid tlw-self-center tlw-justify-items-center tlw-text-center">
      <img class="tlw-pb-10" :src="images.addProgramIcon" />
      <div class="empty-program__title tlw-font-bold tlw-text-2xl tlw-pb-4">
        {{ title }}
      </div>
      <div class="empty-program__subtitle tlw-pb-6">
        {{ subtitle }}
      </div>
      <hs-button data-testid="empty-program__button" variant="primary" @click="goToInviteAffiliatePage">
        {{ buttonText }}
      </hs-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmptyList',
  data() {
    return {
      images: {
        addProgramIcon: require('@/assets/images/SparkAffiliates/ProducerAffiliates/add-suggestion.svg'),
      },
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToInviteAffiliatePage() {
      this.$emit('goToInviteAffiliatePage');
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-program__content {
  width: 408px;
}
</style>
