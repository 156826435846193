<template>
  <div class="invite-link-info">
    <div class="header mb-2">
      <hs-icon class="envelope mb-2" icon="envelope" />
      <h1>Convite enviado!</h1>
    </div>
    <p class="mb-3">
      <b>{{ invite.name }}</b> receberá seu convite por e-mail.
    </p>
    <p class="small">Link de convite individual</p>
    <div class="display-link mb-2" @click="copyLink">
      <a ref="the-link" :href="invite.link" target="_blank">{{ invite.link }}</a> <hs-icon icon="copy" />
    </div>
    <p class="smaller">Este link só pode ser acessado pela pessoa convidada.</p>
    <div class="d-flex justify-content-between mt-4">
      <MButton variant="primary-outline" class="tlw-flex-grow tlw-mr-4" size="sm" @click="goToPendingInvites">
        {{ closeButtonLabel }}
      </MButton>
      <MButton variant="primary" size="sm" @click="goToInviteMore">
        {{ inviteMoreLabel }}
      </MButton>
    </div>
  </div>
</template>

<script>
import toastHelper from '@/shared/helpers/toast';
import MButton from '@/shared/components/MButton.vue';
import Clipboard from '@/shared/mixins/Clipboard';

export default {
  name: 'ParticipationInviteInformation',
  components: { MButton },
  mixins: [Clipboard],
  props: {
    invite: { type: Object },
    closeButtonLabel: { type: String, required: true },
    inviteMoreLabel: { type: String, required: true },
  },
  methods: {
    copyLink() {
      const result = this.clipboardCopy('the-link', this.invite.link);
      if (result) {
        toastHelper.successMessage('Link copiado.');
      } else {
        toastHelper.dangerMessage('Falha ao copiar o link');
      }
    },
    goToPendingInvites() {
      this.$emit('go-to-pending-invites');
    },
    goToInviteMore() {
      this.$emit('go-to-invite-more');
    },
  },
};
</script>

<style lang="scss">
.invite-link-info {
  text-align: center;
  padding: 24px 37px 17px;

  .header {
    color: #348535;

    .envelope {
      font-size: 36px;
    }

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 125%;
    }
  }

  b {
    font-style: normal;
    font-weight: 700;
  }

  p {
    font-style: normal;
    font-weight: 400;
  }

  p,
  b {
    font-size: 16px;
    line-height: 150%;

    color: #262626;
  }

  .small {
    font-size: 14px;
  }

  .smaller {
    font-size: 12px;
  }

  .display-link {
    text-align: left;
    background: #ffffff;

    /* High/Dark */
    border: 2px solid #bababa;
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: justify-between;
    padding: 8px 16px;
    word-break: break-word;

    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #7427f1;
      padding-right: 8px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}
</style>
