<template>
  <div class="tlw-grid tlw-grid-cols-7 tlw-bg-white tlw-shadow-grey tlw-py-6 tlw-px-8 tlw-rounded-md tlw-mb-6">
    <div class="tlw-col-span-1 tlw-mr-6">
      <img :src="images.productCardIcon" />
    </div>
    <div class="tlw-col-span-3 tlw-pr-6">
      <div class="tlw-font-semibold tlw-mb-4">
        {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.card.product') }}
      </div>
      <div class="tlw-font-semibold tlw-text-sm tlw-mb-2">{{ product.productName }}</div>
      <div v-for="offer in product.product_offers" :key="`commission-card-${product.id}-${offer.id}`" class="tlw-mb-1">
        {{ offer.title }}
      </div>
    </div>
    <div class="tlw-col-span-3">
      <div class="tlw-font-semibold tlw-mb-4">
        {{
          $t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.card.commission-percent')
        }}
      </div>
      <div v-if="lock">
        <hs-badge variant="purple" pill> Comissão {{ product.commission }}% </hs-badge>
      </div>
      <div v-else>
        <hs-input
          :placeholder="
            $t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.card.placeholder')
          "
          v-model="product.commission"
          type="number"
          :step="0.01"
          @input="setProductCommission"
          @blur="$v.product.commission.$touch()"
          :state="!$v.product.commission.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.product.commission.$error && !disabledBtn">
            <hs-form-invalid-feedback :state="false" v-if="!$v.product.commission.required">
              {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.card.required') }}
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
        <hs-form-invalid-feedback :state="false" v-if="disabledBtn">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.card.required') }}
        </hs-form-invalid-feedback>
      </div>
      <MButton
        v-if="lock"
        :label="
          $t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.card.edit-commission')
        "
        variant="primary"
        class="tlw-w-3/4 tlw-mt-4"
        @click="editCommission"
        size="sm"
      />

      <MButton
        id="addBtn"
        v-else
        size="sm"
        variant="primary"
        :disabled="!isValidCommission"
        :label="requestEditionButton"
        class="tlw-w-3/4 tlw-mt-4"
        @click="addCommission"
      />
    </div>
  </div>
</template>

<script>
import { between, required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import MButton from '@/shared/components/MButton.vue';

const MIN_AFFILIATE_COMMISSION = 1;
const MAX_AFFILIATE_COMMISSION = 99;

export default {
  directives: { mask },
  name: 'CommissionCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    MButton,
  },
  data() {
    return {
      lock: this.product.commission != null,
      disabledBtn: false,
      images: {
        productCardIcon: require('@/assets/images/SparkAffiliates/Programs/newbox.svg'),
      },
    };
  },
  validations: {
    product: {
      commission: {
        required,
        between: between(MIN_AFFILIATE_COMMISSION, MAX_AFFILIATE_COMMISSION),
      },
    },
  },
  computed: {
    requestEditionButton() {
      return this.hasRequestedEdition
        ? this.$t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.card.save-edition')
        : this.$t('sparkaffiliates.programs-list.new-program.program-creator-form.commission-form.card.add-commission');
    },
  },
  methods: {
    isValidCommission() {
      return between(MIN_AFFILIATE_COMMISSION, MAX_AFFILIATE_COMMISSION)(this.product.commission);
    },
    decimalPrecision(num) {
      return (num || 0).toFixed(2);
    },
    addCommission() {
      if (this.product.commission === 0 || this.product.commission == null) {
        this.disabledBtn = true;
        this.$v.product.commission.required = true;
      } else {
        this.lock = true;
      }
    },
    editCommission() {
      this.lock = false;
    },
    setProductCommission(value) {
      this.$emit('set-product-commission', {
        productId: this.product.sparkecommerce_product_id || this.product.id,
        commission: Math.min(
          MAX_AFFILIATE_COMMISSION,
          Math.max(MIN_AFFILIATE_COMMISSION, this.decimalPrecision(Number(value)))
        ),
      });
    },
  },
};
</script>
