<template>
  <hsModal :id="modalId" size="md" :hide-header="true">
    <div class="media-kit-deletion-modal__container tlw-flex tlw-justify-center tlw-pt-8 tlw-pb-2">
      <div class="media-kit-deletion-modal__content tlw-grid tlw-self-center tlw-justify-items-center tlw-text-center">
        <img class="tlw-pb-10" :src="images.addProgramIcon" />
        <div class="media-kit-deletion-modal__title tlw-font-bold tlw-text-2xl tlw-pb-4">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.deletion-modal.title') }}
        </div>
        <div class="media-kit-deletion-modal__subtitle tlw-pb-6">
          {{
            $t('sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.deletion-modal.subtitle')
          }}
        </div>
        <hs-button
          class="media-kit-deletion-modal__btn tlw-mb-4 tlw-w-4/5"
          variant="danger"
          @click="continueToDeletion"
        >
          {{
            $t(
              'sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.deletion-modal.continue-button'
            )
          }}
        </hs-button>
        <hs-button class="media-kit-deletion-modal__btn tlw-w-4/5" variant="outline-danger" @click="cancelOperation">
          {{
            $t(
              'sparkaffiliates.programs-list.new-program.program-creator-form.media-kit-form.deletion-modal.secondary-button'
            )
          }}
        </hs-button>
      </div>
    </div>
  </hsModal>
</template>
<script>
import { hsModal } from '@/components';

export default {
  name: 'NewProgramAlertModal',
  components: {
    hsModal,
  },
  data() {
    return {
      modalId: `media-kit-deletion-alert${this.productId}`,
      images: {
        addProgramIcon: require('@/assets/images/SparkAffiliates/Programs/warning-robot.svg'),
      },
    };
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    continueToDeletion() {
      const emptyForm = {
        productId: this.productId,
        mediaKit: {
          url: null,
          mediaTypes: [],
        },
      };

      this.$emit('deleteMediaKitParams', emptyForm);
      this.$bvModal.hide(this.modalId);
    },
    cancelOperation() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

<style lang="scss" scoped>
.media-kit-deletion-modal__content {
  width: 408px;
}

.media-kit-deletion-modal__title {
  color: $cherry;
}
</style>
