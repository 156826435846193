<template>
  <div
    class="tlw-grid tlw-bg-white tlw-shadow-grey tlw-rounded-md tlw-gap-6 tlw-p-6 tlw-pl-8 summary-details-card__content"
  >
    <div class="summary-details-card__offer-list">
      <div class="tlw-flex tlw-gap-2">
        <div class="tlw-font-semibold tlw-mb-4">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.details.product') }}
        </div>
        <div @click="goToStep('products')" class="edit-icon__primary-icon tlw-cursor-pointer">
          <hs-icon icon="pencil-alt" />
        </div>
      </div>
      <div class="program-product-title tlw-text-sm tlw-font-semibold tlw-mb-2">{{ product.title }}</div>
      <div v-for="offer in product.product_offers" :key="offer.id" class="program-product-offer tlw-mb-1 tlw-text-sm">
        {{ displayOffer(offer) }}
      </div>
    </div>
    <div class="summary-details-card__commission-type">
      <div class="tlw-flex tlw-gap-2">
        <div class="tlw-font-semibold tlw-mb-4">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.details.commission') }}
        </div>
        <div @click="goToStep('commission')" class="edit-icon__primary-icon tlw-cursor-pointer">
          <hs-icon icon="pencil-alt" />
        </div>
      </div>
      <div class="tlw-font-semibold tlw-text-sm tlw-mb-2">
        {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.details.commission-percent') }}
      </div>
      <hs-badge v-if="commission" variant="purple" pill>Comissão {{ product.commission }}%</hs-badge>
    </div>
    <div class="summary-details-card__media-info">
      <div class="tlw-flex tlw-gap-2">
        <div class="tlw-font-semibold tlw-mb-4">
          {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.details.media-kit') }}
        </div>
        <div @click="goToStep('media-kits')" class="edit-icon__primary-icon tlw-cursor-pointer">
          <hs-icon icon="pencil-alt" />
        </div>
      </div>
      <div class="tlw-text-sm tlw-font-semibold">Mídia kit / {{ product.title }}</div>
      <div class="tlw-text-sm tlw-mt-1">
        {{ $t('sparkaffiliates.programs-list.new-program.program-creator-form.summary.details.address') }}
        <a
          class="program-product-media-kit-url address-link tlw-break-words"
          v-if="hasMediaKits"
          :href="mediaKit.source"
          target="_blank"
          >{{ mediaKit.source }}</a
        >
      </div>
      <div v-if="mediaKit.media_types.length > 0" class="tlw-mt-3 tlw-flex tlw-gap-x-3 tlw-flex-wrap">
        <hs-badge v-for="mtype in mediaKit.media_types" :key="mtype" variant="purple" class="tlw-mb-1" pill>
          {{ mtype }}
        </hs-badge>
      </div>
    </div>
    <div class="summary-details-card__media-icon tlw-justify-self-end">
      <img :src="images.mediaKitIcon" />
    </div>
  </div>
</template>

<script>
import { displayOffer } from '@/libs/program';

export default {
  data() {
    return {
      images: {
        mediaKitIcon: require('@/assets/images/SparkAffiliates/Programs/newbox.svg'),
      },
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    commission: {
      type: Number,
      required: true,
    },
  },
  computed: {
    hasMediaKits() {
      return this.product.media_kits.length > 0;
    },
    mediaKit() {
      return this.product.media_kits[0];
    },
  },
  methods: {
    displayOffer(offer) {
      return displayOffer(offer);
    },
    goToStep(step) {
      this.$emit('goToStep', step);
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-details-card__content {
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'offer-list media-icon'
    'commission-type media-icon'
    'media-info media-info';
}

.summary-details-card__commission-type {
  grid-area: commission-type;
}

.summary-details-card__offer-list {
  grid-area: offer-list;
}

.summary-details-card__media-info {
  grid-area: media-info;
}

.summary-details-card__media-icon {
  grid-area: media-icon;
}

.summary-details-card__danger-icon {
  color: $cherry;
  height: fit-content;
}

.summary-details-card__primary-icon {
  color: $cyan;
  height: fit-content;
}

.edit-icon__primary-icon {
  color: $cyan;
  height: fit-content;
}
.address-link {
  color: $blue;
  text-decoration: underline;
}
</style>
